export enum LocalStorageEnum {
  TOKEN_KEY = 'auth-token',
  REFRESHTOKEN_KEY = 'auth-refreshtoken',
  HASH = 'hash',
  SETTINGS = 'settings',
  ACCESS_TOKEN_PREVIEW = 'accessToken-preview',
  REFRESH_TOKEN_PREVIEW = 'refreshToken-preview',
  ROLE = 'role',
  TWO_FA = '2fa',
  EMAIL = 'email',
  IS_STREAK = 'is-streak'
}
