import {
  ChangeDetectionStrategy, Component, EventEmitter,
  Inject, Injector, Input, OnInit, Output
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";

export interface RadioButtonOption {
  value: string | number,
  label: string
}

@Component({
  selector: 'custom-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonComponent
    }
  ]
})
export default class RadioButtonComponent implements ControlValueAccessor, OnInit {

  @Input() optionList: RadioButtonOption[] = [];

  @Input() tooltipsText: string;

  @Input() enableDeselect = false;

  @Input() isValueNumber = false;

  @Input() tooltipPosition = TooltipPositionEnum.right;

  @Input() isVertical = false;

  @Input() set disabled (value) {
    this.disabled$.next(value)
  }

  @Input() set value(value) {
    this.value$.next(value);
  }

  @Output() optionSelected = new EventEmitter<any>();

  errors$ = new BehaviorSubject([]);

  value$ = new BehaviorSubject(undefined);

  touched = false;

  disabled$ = new BehaviorSubject(false);

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  constructor (
    @Inject(Injector) private injector: Injector
  ) {}

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}
  }

  valueChanged(value) {
    this.optionSelected.next(value.value);
    this.onChange(value.value);
  }

  writeValue(value: any): void {
    this.value$.next(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  onClick(event: any) {
    if (event.target.value && this.enableDeselect) {
      const value = this.isValueNumber ? parseInt(event.target.value) : event.target.value;
      this.writeValue(value === this.control.value ? null : value);
      this.valueChanged({ value: value === this.control.value ? null : value });
    }
  }

}
