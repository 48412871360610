import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { QuestionTypesEnum, QuestionTypesOptions } from "src/app/common/Enums/QuestionTypesEnum";
import { RoundTypesEnum } from "src/app/common/Enums/RoundTypesEnum";

@Injectable()
export class QuestionTypeService {
  questionTypeSelector$ = new BehaviorSubject(QuestionTypesOptions);

  changeQuestionType(roundType: RoundTypesEnum) {
    if (roundType === RoundTypesEnum.Other) {
      this.questionTypeSelector$.next(QuestionTypesOptions
        .filter(value => !this.isScoreQuestion(value.value)));
    } else {
      this.questionTypeSelector$.next(QuestionTypesOptions);
    }
  }

  isScoreQuestion(questionType: QuestionTypesEnum) {
    return questionType === QuestionTypesEnum.SCORE || questionType === QuestionTypesEnum.SCORE_PLUS;
  }
}
