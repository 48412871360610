<div class="dialog-content-container">
  <h1 mat-dialog-title *ngIf="data.dialogTitle">{{data.dialogTitle}}</h1>
  <div mat-dialog-content class="body-medium-regular">
    <p>{{data.dialogContent}}</p>
  </div>
  <div class="buttons" *ngIf="!data.isOneButton; else oneButton">
    <button
      id="stayButton"
      *ngIf="data.showDeclineButton"
      class="regular-button secondary-button"
      mat-stroked-button
      color="primary"
      (click)="closeDialog()"
      mat-dialog-close>{{data.labelOk}}</button>
    <button
      mat-dialog-close
      class="regular-button"
      mat-flat-button
      color="primary"
    >{{data.labelNo}}</button>
  </div>

  <ng-template #oneButton>
    <div class="one-button">
      <button
        mat-dialog-close
        class="regular-button"
        mat-flat-button
        color="primary"
        (click)="closeDialog()">{{data.labelOk}}</button>
    </div>
  </ng-template>
</div>


