import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormGroup, ValidationErrors } from "@angular/forms";
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from "@angular/material/autocomplete";
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, map, takeUntil } from "rxjs";
import { AnswerDetailsModel } from "src/app/common/models/AnswerDetailsModel";
import { getErrorFromValidator } from "src/app/common/modules/validators/validator-text.service";
import { APP_DATA } from "src/app/general.app.config";
import { AnswerGroupsService } from "../../answer-groups.service";
import { MoveDirectionEnum } from "../../../common/Enums/MoveDirectionEnum";
import { LocalizationService } from "../../../core/services/localization.service";

@Component({
    selector: 'question-autocomplete',
    templateUrl: './question-autocomplete.component.html',
    styleUrls: ['./question-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
      {
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: { overlayPanelClass: 'answer-panel-class' }
      }
    ]
})
export class QuestionAutocompleteComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  @Output() onRemoveForm = new EventEmitter<AnswerDetailsModel>();

  @Output() onAddForm = new EventEmitter<void>();

  @Output() onTouchedForm = new EventEmitter<void>();

  @Output() onUpdateAnswer = new EventEmitter<AnswerDetailsModel>();

  @Output() onCreateAnswer = new EventEmitter<AnswerDetailsModel>();

  @Output() onDeleteAnswer = new EventEmitter<AnswerDetailsModel>();

  @Output() onSetAnswer =  new EventEmitter<AnswerDetailsModel>();

  @Output() changeOrder = new EventEmitter<MoveDirectionEnum>();

  @Input() isFirst: boolean;

  @Input() isLast: boolean;

  @Input() fromModalWindow = false;

  @Input() hideImage = false;

  @Input() set form(form: FormGroup) {
    if (form) {
      this.autocompleteForm = form;

      this.answerList$ = combineLatest([
        this.answerText$,
        this.originAnswerList$
      ]).pipe(
        map(([text, answerList]) => answerList.filter(answer => answer.text.includes(text)))
      );

      const value = this.autocompleteForm.getRawValue();
      this.answer$.next(value);
      this.answerImageUrl$.next(value.imageUrl);
      this.answerText$.next(value.text);
      this.answerId$.next(value.id);

      if (form.touched) {
        this.validate();
      }

      this.autocompleteForm.controls['id'].valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
        ).subscribe((data: AnswerDetailsModel) => {
          this.answer$.next(data);
          this.answerImageUrl$.next(data?.imageUrl);
          this.answerText$.next(data?.text);
          this.answerId$.next(data?.id);
          this.validate();
        });
    }
  }

  @Input() set canRemove (value: boolean) {
    this.canRemove$.next(value)
  }

  @Input() set displayAdd(value: boolean) {
    this.displayAdd$.next(value);
  }
  @Input() set answerList(answerList: AnswerDetailsModel[]) {
    const autocompleteData = this.autocompleteForm?.getRawValue();
    if (autocompleteData?.id) {
      const curentAnswer = answerList.find(answer => answer.id === autocompleteData.id);
      if (curentAnswer) {
        if (curentAnswer.text != autocompleteData.text) {
          this.autocompleteForm.get('text').patchValue(curentAnswer.text);
        }
        if (curentAnswer.imageUrl != autocompleteData.imageUrl && !this.hideImage) {
          this.autocompleteForm.get('imageUrl').patchValue(curentAnswer.imageUrl);
        }
      }
    }
    this.originAnswerList$.next(answerList.sort((a, b) => {
      if ( a.id < b.id ){
        return -1;
      }
      if ( a.id > b.id ){
        return 1;
      }
      return 0;
    }));
  }

  originAnswerList$ = new BehaviorSubject<AnswerDetailsModel[]>([]);

  touched$ = new BehaviorSubject(false);

  canRemove$ = new BehaviorSubject(false);

  displayAdd$ = new BehaviorSubject(false);

  autocompleteForm: FormGroup;

  maxAnswerTextLength = 128;

  appData = APP_DATA;

  answer$ = new BehaviorSubject<AnswerDetailsModel>({
    id: null,
    text: null,
    imageUrl: null,
    answerGroupId: null,
    answerGroupName: null
  });

  originAnswerText$ = this.answer$.pipe(
    map(answer => answer?.text)
  );

  answerText$ = new BehaviorSubject('');
  answerId$ = new BehaviorSubject(undefined);
  answerImageUrl$ = new BehaviorSubject(undefined);

  answerImage$ = this.answerImageUrl$.pipe(
    map(([imageUrl]) => ({
      imageUrl
    }))
  );

  answerList$: Observable<AnswerDetailsModel[]>;

  editId$ = new BehaviorSubject(undefined);

  editImageUrl$ = new BehaviorSubject(undefined);

  errors$ = new BehaviorSubject([]);

  isEditedText$ = combineLatest([
    this.originAnswerText$,
    this.answerText$
  ]).pipe(
    map(([originText, text]) =>
      originText !== text &&  (originText !== undefined || text !== ''))
  );

  readonly MoveDirectionEnum = MoveDirectionEnum;

  constructor(
    public answerGroupsService: AnswerGroupsService,
    public localizationService: LocalizationService
  ) {
  }

  add(event) {
    event.stopPropagation()
    this.onAddForm.emit()
  }

  touched() {
    this.onTouchedForm.emit();
    if (!this.touched$.value) {
      this.autocompleteForm.markAsTouched();
      this.validate();
    }
  }

  deleteAnswer(answer: AnswerDetailsModel, event) {
    event.stopPropagation();
    this.onDeleteAnswer.emit(answer);
  }

  prevent(event) {
    event.stopPropagation();
  }

  trackByAnswerList(index: number, item: AnswerDetailsModel) {
    return `${item.id}_${item.text}_${item.imageUrl}`;
  }

  async addImageToEdit(image) {
    this.editImageUrl$.next(image?.url);
  }

  validate() {
    const errors = [];
    if (this.autocompleteForm?.errors) {
      Object.keys(this.autocompleteForm.errors as ValidationErrors).forEach(key => {
        if(this.autocompleteForm.errors[key]) {
          errors.push(getErrorFromValidator(key, this.autocompleteForm.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }

  async create(event) {
    event.stopPropagation();
    const answer: AnswerDetailsModel = {
      text: await firstValueFrom(this.answerText$),
      imageUrl: await firstValueFrom(this.answerImageUrl$)
    };
    if (answer.text) {
      this.onCreateAnswer.emit(answer);
    }
  }

  changeOrderEvent(event: any, direction: MoveDirectionEnum) {
    event.stopPropagation();
    this.changeOrder.emit(direction)
  }

  async updateAnswer(event, text: string, answer: AnswerDetailsModel) {
    const {id, externalData} = answer
    event.stopPropagation();
    this.onUpdateAnswer.emit({
      id,
      imageUrl: await firstValueFrom(this.editImageUrl$) || null,
      text,
      externalData
    });
    this.editId$.next(undefined);
    this.editImageUrl$.next(undefined);
  }

  async toEdit(id: string, event) {
    event.stopPropagation();
    this.editId$.next(id)
  }

  input(event: InputEvent) {
    this.answerText$.next((event.target as any).value);
  }

  async keyPress(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === "Enter") {
      const answer: AnswerDetailsModel = {
        text: await firstValueFrom(this.answerText$),
        imageUrl: await firstValueFrom(this.answerImageUrl$)
      };
      if (answer.text) {
        this.onCreateAnswer.emit(answer);
      }
    }
  }

  async addImageToMain(image) {
    this.answerImageUrl$.next(image?.url);
  }

  setAnswer(answer: AnswerDetailsModel) {
    this.onSetAnswer.emit(answer);
  }

  async removeAnswerForm() {
    const answer = await firstValueFrom(this.answer$);
    this.onRemoveForm.emit(answer);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  processEditKeydown(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.stopPropagation();
    }
  }

  isAnswerExist(answers, currentAnswers) {
    if (!currentAnswers?.id || !answers || !answers.length) return false;
    return answers.includes(currentAnswers.id);
  }

  isShowControlIcon(isVendorLocalize, answer) {
    if (!isVendorLocalize) return true;
    const {isExternal, isExternalApi} = answer;
    return !isExternal && !isExternalApi;
  }
}
