import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { LocalStorageService } from "./local-storage.service";
import { BehaviorSubject, catchError, Observable, tap, throwError } from "rxjs";
import { QRTwoFaModel } from "../../common/models/QRTwoFaModel";
import { SnackBarService } from "./snack-bar.service";
import {LocalStorageEnum} from "../../common/Enums/LocalStorageEnum";


@Injectable({
  providedIn: 'root'
})
export class TwoFaAuthService {

  error$ = new BehaviorSubject<string>('');
  isTwoFaInProgress$ = new BehaviorSubject<boolean>(false);
  userId: number;

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
    private localStorageService: LocalStorageService,
    private snackbarService: SnackBarService
  ) { }


  generateQRForTwoFa(): Observable<QRTwoFaModel> {
    this.isTwoFaInProgress$.next(true);
    return this.http.post<QRTwoFaModel>(this.uriConfig.generateQR, {});
  }

  verifyTwoFa(code: string): Observable<any> {
    return this.http.post<any>(this.uriConfig.verifyTwoFa, {code: code}).pipe(
      tap(() => {
        this.localStorageService.setItem(LocalStorageEnum.TWO_FA, 'active');
        this.error$.next('');
        this.snackbarService.showSnackBar('The 2fa authentication is enabled successfully!');
        this.isTwoFaInProgress$.next(false);
      }),
      catchError((err) => {
        let massage = err.error.message[0];
        if (err.status === 401) {
          massage = 'Invalid code';
        }
        this.error$.next(massage);
        return throwError(massage);
      }),
    );
  }

  loginTwoFa(code: string): Observable<{ accessToken: string, refreshToken: string }> {
    this.isTwoFaInProgress$.next(true);
    return this.http.post<{accessToken: string, refreshToken: string }>(this.uriConfig.loginTwoFa, {code: code, userId: this.userId}).pipe(
      tap(() => {
        this.localStorageService.setItem(LocalStorageEnum.TWO_FA, 'active');
        this.error$.next('');
        this.isTwoFaInProgress$.next(false);
      }),
      catchError((err) => {
        let massage = err.error.message[0];
        if (err.status === 401) {
          massage = 'Invalid code';
        }
        this.error$.next(massage);
        return throwError(massage);
      }),
    );
  }

  deleteTwoFa(): Observable<any> {
    return this.http.delete<any>(this.uriConfig.deleteTwoFa).pipe(
      tap(() => {
        this.localStorageService.removeItem(LocalStorageEnum.TWO_FA);
        this.snackbarService.showSnackBar('The 2fa authentication is disabled successfully!');
        this.isTwoFaInProgress$.next(false);
      }),
      catchError((err) => {
        const massage = err.error.message;
        return throwError(massage);
      }),
    );
  }


}


