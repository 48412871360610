
<div
  class="form-field-with-label">
  <mat-label>Answer Groups</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      placeholder="Select an answer group"
      (input)="inputName($event)"
      [matAutocomplete]="eventAuto"
      (focus)="markAsTouched()"
      [formControl]="control">
    <mat-icon
      class="event-suffix"
      matSuffix>
      manage_search
    </mat-icon>
    <mat-autocomplete
      scrollControl
      [displayWith]="displayWith()"
      (optionSelected)="setGroup($event.option.value)"
      #eventAuto="matAutocomplete">
      <mat-option
        *ngFor="let group of (groupList$ | async)"
        [value]="group.id"
        [id]="group.id"
        class="event-option">
        <div class="mat-option-item event">
            <div class="name">
              {{group.name}}
            </div>
            <mat-icon
              matRipple
              [matRippleRadius]="10"
              [matRippleUnbounded]="true"
              [matRippleCentered]="true"
              matSuffix class="material-icons material-icons-outlined"
              (click)="editGroup($event, group)">
              edit
            </mat-icon>
            <mat-icon
              matRipple
              [matRippleRadius]="10"
              [matRippleUnbounded]="true"
              [matRippleCentered]="true"
              matSuffix class="material-icons material-icons-outlined"
              (click)="deleteGroup($event, group)">
              delete_outline
            </mat-icon>
        </div>

      </mat-option>
      <mat-option class="create-new-item" (click)="openCreateModal($event)">
        <mat-icon class="material-icons">add_circle_outline</mat-icon>
        Create a new answer group
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngFor="let error of errors$  | async">
    <i class="fas fa-exclamation-circle"></i>
    <span>{{error}}</span>
  </mat-error>
</div>
