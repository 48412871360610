<modal-header>
  <h2 header-name>{{wizzardHeader}}</h2>
  <status-chip [customColor]="'#D5EE92'" chips>
    {{(roundHelperService.isMultiEventRound$ | async) ? 'Multi' : 'Single'}}
  </status-chip>

  <ng-container buttons-set>
    <button
      *ngIf="displayDraftButton$ | async"
      (click)="saveDraft.emit()"
      mat-stroked-button
      color="primary">
      Save Draft
    </button>

    <button
      *ngIf="displayPublishButton$ | async"
      (click)="previewAndPublish.emit()"
      mat-raised-button
      color="primary">
      Preview and Publish
    </button>

    <ng-container *ngIf="displaySubmitButton$ | async">
      <button
        *ngIf="displayUpdateImageButton()"
        (click)="editRoundImage.emit()"
        mat-raised-button
        color="primary">
        Edit round image
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="submitCorrectAnswers.emit()"
        [disabled]="isSubmitAnswersDisabled() | async">
        Submit correct result
      </button>
    </ng-container>


    <i
      *ngIf="!(roundHelperService?.isMultiEventRound$ | async) && (isFinishedRound$ | async)"
      class="fa-solid fa-circle-info tooltip"
      [matTooltipPosition]="TooltipPositionEnum.left"
      #tooltip="matTooltip"
      [matTooltip]="'Please ensure you selected the correct answers for all questions as well as the event score'"
      [matTooltipClass]="TooltipPositionEnum.left"></i>
  </ng-container>
</modal-header>
<div class="tabs-container">
<tabs></tabs>
</div>
