import {
  ChangeDetectionStrategy, Component, EventEmitter,
  Inject, Injector, Input, OnInit, Output
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ValidationErrors
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { getErrorFromValidator } from "../../../modules/validators/validator-text.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";

@Component({
  selector: 'custom-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    }
  ]
})
export default class DateInputComponent implements ControlValueAccessor, OnInit {

  @Input() pickerType: 'both' | 'calendar' | 'timer' = 'both';

  @Input() tooltipsText: string;

  @Input() tooltipPosition = TooltipPositionEnum.right

  @Input() placeholder = "Choose a date";

  @Input() isAbleToClear = false;

  @Output() clearValue = new EventEmitter<void>;

  errors$ = new BehaviorSubject([]);

  value$ = new BehaviorSubject(undefined);

  touched = false;

  disabled$ = new BehaviorSubject(false);

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  constructor (
    @Inject(Injector) private injector: Injector
  ) {}

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}
    if (this.control.touched) {
      this.validate();
    }
  }

  valueChanged(value) {
    this.onChange(value);
    this.validate();
  }

  validate() {
    const errors = [];
    if (this.control?.errors) {
      Object.keys(this.control.errors as ValidationErrors).forEach(key => {
        if(this.control.errors[key]) {
          errors.push(getErrorFromValidator(key, this.control.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }

  writeValue(value: any): void {
    this.value$.next(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.validate();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

}
