<div class="form-group">
  <form [formGroup]="singlePrizeForm" class="form-container" [ngClass]="{'no-control': !(predictionPrizeSectionService.isAnyActionAvailable$ | async)}">
    <div class="forms">
      <div class="form-field-with-label" *ngIf="!singlePrizeForm.get('assignment').disabled">
        <mat-label>
          Split prize pool*
        </mat-label>
        <mat-slide-toggle
          color="primary"
          formControlName="assignment">
        </mat-slide-toggle>
      </div>
      <custom-select
        formControlName="questionCount"
        [style.width]="singlePrizeForm.get('assignment').disabled ? '48%' : '35%'"
        [selectOptions]="predictionPrizeSectionService.questionCount$ | async"
        [placeholder]="'Choose the option from drop-down'">
        Correct answers*
      </custom-select>
      <ng-container
        *ngIf="(predictionPrizeSectionService.roundPrizeType$ | async) === RoundPrizeTypeEnum.CASH_POINTS; else labelPrize">
        <custom-input
          [style.width]="singlePrizeForm.get('assignment').disabled ? '48%' : '35%'"
          [type]="'number'"
          formControlName="cashPoints"
          [placeholder]="'Сash Points'">
          Prize amount*
        </custom-input>
      </ng-container>
      <ng-template #labelPrize>
        <custom-input
          [style.width]="singlePrizeForm.get('assignment').disabled ? '48%' : '35%'"
          formControlName="prize"
          [placeholder]="'Prize'">
          Prize*
        </custom-input>
      </ng-template>
    </div>

  </form>
  <div class="control-container" *ngIf="predictionPrizeSectionService.isAnyActionAvailable$ | async">
    <div class="icons">
      <mat-icon
        *ngIf="predictionPrizeSectionService.isAddingAvailable$ | async"
        matRipple
        [matRippleRadius]="20"
        [matRippleUnbounded]="true"
        [matRippleCentered]="true"
        class="material-icons material-icons-outlined"
        (click)="addNewPrizeLine()">add_circle_outline
      </mat-icon>

      <mat-icon
        *ngIf="predictionPrizeSectionService.isDeleteAvailable$ | async"
        matRipple
        [matRippleRadius]="20"
        [matRippleUnbounded]="true"
        [matRippleCentered]="true"
        class="material-icons material-icons-outlined"
        (click)="removePrizeLine()"
      >delete_outline
      </mat-icon>
    </div>
  </div>
</div>
