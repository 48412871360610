import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from "rxjs";
import { AnswerGroupsService } from "src/app/new-round-wizzard/answer-groups.service";
import { AbstractControl, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgModel, ValidationErrors } from "@angular/forms";
import { getErrorFromValidator } from "src/app/common/modules/validators/validator-text.service";
import { QuestionTypesEnum } from "src/app/common/Enums/QuestionTypesEnum";

@Component({
  selector: 'select-create-group',
  templateUrl: './select-create-group.component.html',
  styleUrls: ['./select-create-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectCreateGroupComponent,
    }
  ]
})
export class SelectCreateGroupComponent implements OnInit, ControlValueAccessor {

  private unsubscribe$: Subject<void> = new Subject();

  questionType$ = new BehaviorSubject(undefined);

  private _questionType;

  @Input()
  set questionType(value: QuestionTypesEnum) {
    this._questionType = value;
    this.questionType$.next(value);
  }

  get questionType() {
    return this._questionType;
  }

  answerGroupList$ = new BehaviorSubject([])

  @Input()
  set answerGroupList(value: []) {
    this.answerGroupList$.next(value);
  }

  @Output()
  onEditGroup = new EventEmitter();

  @Output()
  onDeleteGroup = new EventEmitter();

  @Output()
  onCreateGroup = new EventEmitter();

  disabled$ = new BehaviorSubject(false);

  value$  = new BehaviorSubject(undefined);

  errors$ = new BehaviorSubject([]);

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  touched = false;

  groupName$ = new BehaviorSubject('');

  groupList$ = combineLatest([
    this.answerGroupList$.asObservable(),
    this.groupName$,
  ]).pipe(
    map(([originGroups, groupName]) => originGroups.filter(group => group.name.includes(groupName))),
  )

  constructor (
    private answerGroupsService: AnswerGroupsService,
    @Inject(Injector) private injector: Injector,
  ) {}

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}

    this.control.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.validate());
  }

  displayWith() {
    return (option) => {
      return this.answerGroupsService.answerGroupList?.find(item => item.id === option)?.name
    }
  }

  validate() {
    const errors = [];
    if (this.control?.errors) {
      Object.keys(this.control.errors as ValidationErrors).forEach(key => {
        if(this.control?.errors?.[key]) {
          errors.push(getErrorFromValidator(key, this.control.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }


  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  writeValue(value: string): void {
    this.value$.next(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.validate();
    }
  }

  inputName(event) {
    event.stopPropagation();
    this.groupName$.next(event.target.value);
  }

  setGroup(groupId: number) {
    this.control.patchValue(groupId);
    this.onChange(groupId);
    this.value$.next(groupId);
    this.validate();
  }

  editGroup(event, group) {
    event.stopPropagation();
    this.onEditGroup.emit(group);
  }

  deleteGroup(event, group) {
    event.stopPropagation();
    this.onDeleteGroup.emit(group);
  }

  openCreateModal(event) {
    event.stopPropagation();
    this.onCreateGroup.emit();
  }

}
