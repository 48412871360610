import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ProfileService} from "./core/services/profile.service";
import {CurrentLocationService} from "./core/services/current-location.service";
import {Title} from "@angular/platform-browser";
import {TextService} from "./core/services/text.service";
import {SaasSettingsService} from "./core/services/saas-settings.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  currentYear: number;

  pagePath: string;

  private APP_NAME = 'Hunch App';

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private profileService: ProfileService,
    private titleService: Title,
    private currentLocationService: CurrentLocationService,
    private textService: TextService,
    public saasSettingsService: SaasSettingsService
  ) {
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.currentLocationService.getCurrentLocation();
    this.currentLocationService.getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pagePath => {
        this.pagePath = pagePath;
        const pageName = this.textService.createNameWithSpaces(pagePath);
        this.titleService.setTitle(pageName + ' - ' + this.APP_NAME);
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

