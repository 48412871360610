import moment from "moment/moment";

export const closeDateValidator = () => (control) => {
  const dateNow = Date.now();
  if (control.value && !moment(dateNow).isBefore(control.value)) {
    return {
      invalidCloseDate: {value: control.value}
    }
  }
  return null;
};
