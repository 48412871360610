import { FormGroup, ValidatorFn } from "@angular/forms";
import moment from 'moment';
import { Injectable } from "@angular/core";


@Injectable({providedIn: 'root'})

export class IsCloseDateAfterOpenService {
  /**
   * Check that close date is after open date
   */
  public validateDates(startDateKey?: string, endDateKey?: string): ValidatorFn {
    return (formGroup: FormGroup) => {

      const startDate = startDateKey ? formGroup.get(startDateKey)?.value : formGroup.get('startDate')?.value;

      const endDate = endDateKey ? formGroup.get(endDateKey)?.value : formGroup.get('endDate')?.value;

      if (!startDate || !endDate) {
        return null;
      }
      if (moment(endDate).isBefore(startDate)) {
        return { datesOrderWrong: true };
      }
      return null;
    };
  }

  dateErrorChecker(form: FormGroup) {
    if (form.errors?.datesOrderWrong) {
      form.controls['closeDate'].setErrors({'incorrect': true});
      form.controls['startDate'].setErrors({'incorrectStartDate': true});
      form.controls['startDate'].markAsTouched();
      form.controls['closeDate'].markAsTouched();
    } else {
      const openDateError = form.controls['startDate'].errors;
      const closeDateError = form.controls['closeDate'].errors;
      if (closeDateError && closeDateError['incorrect']) {
        delete closeDateError['incorrect'];
        form.controls['closeDate'].patchValue(form.controls['closeDate'].value);
      }

      if (openDateError && openDateError['incorrectStartDate']) {
        delete openDateError['incorrectStartDate'];
        form.controls['startDate'].patchValue(form.controls['startDate'].value);
      }


      if (openDateError) {
        form.controls['startDate'].setErrors(null);
        form.controls['startDate'].setErrors(openDateError);
      }
      if (closeDateError) {
        form.controls['closeDate'].setErrors(null);
        form.controls['closeDate'].setErrors(closeDateError);
      }
    }

    if (form.controls['startDate'].value
      && form.controls['closeDate'].value
      && !form.errors?.datesOrderWrong
      && !form.controls['startDate'].getError('invalidOpenDate')
      && !form.controls['closeDate'].getError('invalidCloseDate')
    ) {
      form.controls['startDate'].setErrors(null);
      form.controls['closeDate'].setErrors(null);
    }

    return form;
  }
}
