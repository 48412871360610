import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import { APP_ROUTES } from 'src/app/app.routes.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * check if user token is set, navigate to dashboard, if no, navigate to login for vendor
   */
  getCurrentUser(): Observable<any> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (!user) {
          this.router.navigate(['/auth/login']);
        } else {
          const role = this.authService.getCurrentRole();
          if (!role) {
            this.router.navigate(['/auth/login']);
            this.authService.logOut();
            return;
          }
          if (role === 'VENDOR') {
            return user;
          } else {
            this.router.navigate([`/a/${APP_ROUTES.VENDORS}`]);
          }
        }
      })
    );
  }
}
