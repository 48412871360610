<div class="section-container">
  <div class="header body-big-bold">
    <div class="header-title">
      <ng-content select="[header]"></ng-content>
    </div>
    <ng-content select="[button]"></ng-content>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
