import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimDoubleSpace]'
})
export class TrimDoubleSpaceDirective {

  value: string;

  constructor(
    public ref: ElementRef,
    public ngControl: NgControl
  ) {
  }

  @HostListener('input', ['$event']) onInput($event) {
    const value = $event.target.value;
    const updateValue = /\s{2,}/.test(value) ? value.replace(/\s+/g, ' ') : value;

    $event.target.value = updateValue;
    if (this.ngControl.name !== 'title' && this.ngControl.name) {
      this.ngControl.control.setValue(updateValue);
    }

    this.value = updateValue;
    $event.preventDefault();
  }

}
