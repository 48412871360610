import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'image-with-loader',
  templateUrl: './image-with-loader.component.html',
  styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent {
  @Input() imageSrc: string;

  @Input() overlayHeight: string;

  @Input() fixedOverleyWidth = '100%';

  @Input() spinnerSize: number;

  @Input() additionClass: string[];

  @Input() additionOverlayClass: string[];

  @Output() imageLoaded = new EventEmitter<void>();

  isImageLoading = false;

  /**
   * Event when image loaded in browser
   */
  onImageLoaded () {
    this.isImageLoading = true;
    this.imageLoaded.emit();
  }

  /**
   * If patent component has specific styles for current image, show this style
   */
  getClassName () {
    const classes = {};
    if (this.additionClass) {
      this.additionClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }

  /**
   * If patent component has specific styles for current loader, show this style
   */
  getOverlayClassName() {
    const classes = {};
    if (this.additionOverlayClass) {
      this.additionOverlayClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }
}
