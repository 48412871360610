import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsControllService } from "../../../core/services/tabs-controll.service";
import { TabsEnum } from "../../Enums/TabsEnum";

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {

  tabsNameList$: BehaviorSubject<{ name: TabsEnum, isActive: boolean, id: string }[]>;

  constructor(
    public tabsControllService: TabsControllService
  ) {
    this.tabsNameList$ = this.tabsControllService.tabsNameList$;
  }

  selectTab(tabId) {
    this.tabsControllService.scrollToElement(tabId);
  }
}
