<div class="create-event-container">

  <div *ngIf="loading" class="disabled-overlay">
    <mat-spinner [diameter]="30" color="primary"></mat-spinner>
  </div>

  <div class="create-event-header body-medium-medium">Answer Groups</div>
  <div class="create-event-body">
    <form [formGroup]="groupForm">
      <custom-select
        formControlName="type"
        [selectOptions]="questionTypeService.questionTypeSelector$ | async">
        Question Type*
      </custom-select>
      <custom-input
        formControlName="groupName"
        placeholder="Enter Group Name"
        [groupNameError]="groupNameError$ | async"
      >
        Group Name*
      </custom-input>
      <answers-question
        [fromModalWindow]="true"
        [form]="groupForm.get('additional')"
        [_isExternalEvent]="data.disableExternalEvent ? false : (data.event || getSingleRoundExternalEvent())"
        [_questionType]="data.questionType"
        [maxAnswerLength]="maxAnswerLength$ | async">
      </answers-question>
    </form>

  </div>
  <div class="buttons">
    <button
      id="stayButton"
      class="regular-button secondary-button"
      mat-stroked-button
      color="primary"
      mat-dialog-close>Cancel</button>
    <button
      class="regular-button"
      mat-flat-button
      color="primary"
      [disabled]="groupForm.invalid || !!(answerGroupsService.existingAnswersId$ | async).length"
      (click)="saveGroup()"
    >Save</button>
  </div>
</div>
