
<div
  class="form-field-with-label"
  [ngClass]="{valid: (control.touched && control.valid || control.disabled && control.value)}">
  <mat-label>Event* </mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      placeholder="Select an event"
      (input)="inputEventName($event)"
      [matAutocomplete]="eventAuto"
      (focusout)="markAsTouched()"
      [formControl]="control">
    <mat-icon
      class="event-suffix"

      matSuffix>
      manage_search
    </mat-icon>
    <mat-autocomplete
      [displayWith]="getEventName"
      (optionSelected)="setEvent($event)"
      #eventAuto="matAutocomplete">
      <mat-option
        class="event-option"
        *ngFor="let event of (sportEvents$ | async)"
        [value]="event"
        [id]="event.id">
        <div class="mat-option-item event">
            <div class="name">{{event.name}}</div>
          <status-chip [customColor]="'#D5EE92'" *ngIf="!!event.externalId">
            {{appData.EXTERNAL}}
          </status-chip>
            <div class="date body-small-regular" *ngIf="event.startDate">
              {{event.startDate | datesFormatPipe: dateFormats.DD_MMMM_YYYY_hh_mmtz}}
            </div>
          <ng-container *ngIf="event.closeDate">
            -
            <div class="date body-small-regular">
              {{event.closeDate | datesFormatPipe: dateFormats.DD_MMMM_YYYY_hh_mmtz}}
            </div>
          </ng-container>
              <mat-icon
              matRipple
              [matRippleRadius]="10"
              [matRippleUnbounded]="true"
              [matRippleCentered]="true"
              matSuffix class="material-icons material-icons-outlined"
              (click)="editEvent($event, event)">edit
            </mat-icon>
            <mat-icon
              matRipple
              [matRippleRadius]="10"
              [matRippleUnbounded]="true"
              [matRippleCentered]="true"
              matSuffix class="material-icons material-icons-outlined"
              (click)="deleteEvent($event, event)">delete_outline
            </mat-icon>
        </div>

      </mat-option>
      <mat-option (click)="createEvent($event)" class="create-new-item">
        <mat-icon class="material-icons">add_circle_outline</mat-icon>
        Create a new event
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngFor="let error of errors$  | async">
    <i class="fas fa-exclamation-circle"></i>
    <span>{{error}}</span>
  </mat-error>
</div>
