import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TooltipPositionEnum } from "../../../../Enums/TooltipPositionEnum";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { QuestionDetailsModel, VoidType } from "../../../../models/QuestionDetailsModel";
import { TextService } from "../../../../../core/services/text.service";
import {
  EventDetailsFormInterface,
  SingleAnswersDetailsFormInterface
} from "../../../../FormsModels/correct-answers-details-form-interface";
import { QuestionTypesEnum } from "../../../../Enums/QuestionTypesEnum";
import { BehaviorSubject } from 'rxjs';
import { EventDetailsModel } from "../../../../models/EventDetailsModel";
import { StreakQuestionListItemModel } from "../../../../models/StreakQuestionListItemModel";
import { CorrectAnswerHelperService } from "../../../../../core/services/streak/correct-answer-helper.service";
import { StreakQuestionDifficultyEnum } from "../../../../Enums/StreakQuestionDifficultyEnum";
import { LabelChipTypeEnum } from "../../../../Enums/LabelChipTypeEnum";

@Component({
  selector: 'right-score-answer',
  templateUrl: './right-score-answer.component.html',
  styleUrls: ['./right-score-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightScoreAnswerComponent implements OnInit {

  singleQuestionForm: FormGroup<SingleAnswersDetailsFormInterface | EventDetailsFormInterface>;

  question: QuestionDetailsModel | StreakQuestionListItemModel;

  difficultyLevel: StreakQuestionDifficultyEnum;

  voidType: VoidType;

  isMatched: boolean;

  @Input() set _question (question) {
    this.question = question;
    this.difficultyLevel = this.correctAnswerHelperService.getDifficultyLevel(question);
    this.voidType = this.correctAnswerHelperService.getVoidType(question);
    this.isMatched = this.correctAnswerHelperService.getIsMatch(question);
    if (question.type === QuestionTypesEnum.SCORE_PLUS) {
      this.scoreOption$.next(this.getScoreOptions());
    }
  }

  @Input() event: EventDetailsModel;

  @Input() voidOptions:  { value: VoidType, label: string }[];

  @Input() predictionNumber: number;

  @Input() isWarningNeed: boolean;

  TooltipPositionEnum = TooltipPositionEnum;

  QuestionTypesEnum = QuestionTypesEnum;

  scoreOption$= new BehaviorSubject<{ value: string , label: string }[]>([]);

  protected readonly LabelChipTypeEnum = LabelChipTypeEnum;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private correctAnswerHelperService: CorrectAnswerHelperService,
    public textService: TextService,
  ) {
  }

  ngOnInit(): void {
    this.singleQuestionForm = this.rootFormGroup.control;
  }

  getScoreOptions() {
    const maxScoreValue = parseInt(this.question.maxScoreValue);
    return Array.from(Array(maxScoreValue + 1).keys())
        .map(item => {
          const value = item === maxScoreValue ? `${item}+` : item.toString();
          return {
            value,
            label: value,
          }
        });
  }
}
