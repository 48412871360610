import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { SinglePrizeFormInterface } from "../../../../common/FormsModels/create-round-prize-form-interface";
import { PredictionPrizeSectionService } from "../prize-section.service";
import { RoundPrizeTypeEnum } from "../../../../common/Enums/RoundPrizeTypeEnum";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RoundHelperService } from "../../../round-helper.service";

@Component({
  selector: 'prize-item',
  templateUrl: './prize-item.component.html',
  styleUrls: ['./prize-item.component.scss']
})
export class PrizeItemComponent  implements OnInit, OnDestroy {

  singlePrizeForm: FormGroup<SinglePrizeFormInterface>;

  @Input() index: number;

  isPrizeTypeDisplayed$ = new BehaviorSubject(false);

  protected readonly RoundPrizeTypeEnum = RoundPrizeTypeEnum;

  private unsubscribe$ = new Subject<void>()


  constructor(
    public predictionPrizeSectionService: PredictionPrizeSectionService,
    private rootFormGroup: FormGroupDirective,
    public roundHelperService: RoundHelperService,
  ) {}

  ngOnInit(): void {
    this.singlePrizeForm = this.rootFormGroup.control;

    this.predictionPrizeSectionService.roundPrizeType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: RoundPrizeTypeEnum) => {
        if (type === RoundPrizeTypeEnum.LABEL) {
          this.singlePrizeForm.get('assignment').patchValue(false);
          this.singlePrizeForm.get('assignment').disable()
        } else {
          this.singlePrizeForm.get('assignment').enable()
        }
    })


  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  addNewPrizeLine() {
    this.predictionPrizeSectionService.addFormControl();
  }

  removePrizeLine() {
    this.predictionPrizeSectionService.removeFormControl(this.index);
  }

}
