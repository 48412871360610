<div class="create-event-container">
  <div class="create-event-header body-medium-medium">{{header}}</div>
  <div class="create-event-body">
    <form [formGroup]="eventDetailsForm">
      <div class="team-with-image">
        <div class="form-field-with-label">
          <mat-label>Home Team*</mat-label>
          <mat-form-field appearance="outline" class="autocomplete-selector-item">
            <input
              matInput
              placeholder="Select a team"
              #homeTeamInput
              [matAutocomplete]="homeTeamAuto"
              [formControlName]="'homeTeamId'"
              (input)="onAutocompleteInputEvent($event, 'homeTeam')"
              (keyup.enter)="addNewTeam($event,'homeTeam')">
            <mat-icon class="drop-arrow" *ngIf="!isShowDoneEnterIcon('homeTeam')" matSuffix>arrow_drop_down</mat-icon>

            <mat-icon
              matSuffix
              *ngIf="isShowDoneEnterIcon('homeTeam')"
              (click)="addNewTeam($event,'homeTeam')">
              done
            </mat-icon>

            <mat-autocomplete
              (closed)="onCloseAnswerSelector()"
              [displayWith]="getOptionText"
              (optionSelected)="changeTeam($event.option.value, 'homeTeam')"
              #homeTeamAuto="matAutocomplete">
              <mat-option
                *ngFor="let team of filteredTeamList|filter: eventDetailsForm.get('homeTeamId').value:'name'"
                [value]="team"
                [id]="team.id">
                <div *ngIf="team.id === editedId; else regularItem" class="edit-input 123">
                  <div class="div-with-error" [ngClass]="{'invalid': showEditTeamRequiredError || showEditTeamMaxLenghtError}">
                    <div class="team-input-container">
                      <input
                        (input)="onTeamInputChange(teamName)"
                        [value]="team.name"
                        (keydown)="$event.stopPropagation()"
                        #teamName
                        (click)="prevent($event)"
                        (keyup.enter)="saveTeam($event, teamName.value, 'homeTeam')">

                    </div>
                    <div class="team-edit-container">
                      <small-file-loader
                        [image]="team"
                        (click)="prevent($event)"
                        (fileUpload)="uploadAnswerImage($event)"
                        (imageErrorMassage)="imageHomeErrorMassege = $event">
                      </small-file-loader>
                      <ngx-colors
                        *ngIf="!teamsImages[editedId]?.url"
                        ngx-colors-trigger
                        (open)="onOpenColorPickerPanel()"
                        (click)="prevent($event)"
                        [formControl]="homeTeamColor"
                      ></ngx-colors>
                    </div>

                  </div>
                  <mat-error *ngIf="showEditTeamRequiredError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MANDATORY}}</span>
                  </mat-error>
                  <mat-error *ngIf="showEditTeamMaxLenghtError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MAX_LENGHT_52}}</span>
                  </mat-error>
                  <mat-error *ngIf="showEditTeamMinLenghtError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MIN_LENGHT_2}}</span>
                  </mat-error>
                  <mat-error *ngIf="imageHomeErrorMassege">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.BIG_IMAGE}}</span>
                  </mat-error>
                </div>
                <ng-template #regularItem>
                  <div class="mat-option-item team">
                    <ng-container>
                      <div class="image-loader-container">
                        <image-with-loader
                          *ngIf="team.imageUrl"
                          [overlayHeight]="'24px'"
                          [spinnerSize]="20"
                          (imageErrorMassage)="imageHomeErrorMassege = $event"
                          [imageSrc]="team.imageUrl" class="cell-image">
                        </image-with-loader>
                        <div class="team-color-container" *ngIf="team.color && !team.imageUrl && team.id !== editedId">
                          <div class="team-color-wrapper">
                            <div class="team-color" [style.background-color]="team.color"></div>
                          </div>
                        </div>
                        <div class="empty-space" *ngIf="!team.color && !team.imageUrl">

                        </div>
                      </div>
                    </ng-container>

                    <div class="text-item-part">
                      {{ team.name }}
                    </div>

                    <div class="icons-part">
                      <mat-icon matSuffix class="material-icons material-icons-outlined"
                                (click)="editTeam($event, team, 'homeTeam')">edit
                      </mat-icon>
                      <mat-icon matSuffix class="material-icons material-icons-outlined"
                                (click)="deleteTeam($event, team)">delete_outline
                      </mat-icon>
                    </div>
                  </div>
                </ng-template>

              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error *ngIf="isShowError('homeTeamId', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY_SELECT}}</span>
          </mat-error>
          <mat-error *ngIf="isShowAddingError('homeTeamId')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MAX_LENGHT_52}}</span>
          </mat-error>
        </div>
        <ng-container *ngIf="!isShowDoneEnterIcon('homeTeam'); else newItemAdding">
          <div class="image-container" *ngIf="getEventTeamAsset(homeTeamName); else selectedEmptySpace">
            <image-with-loader
              *ngIf="homeTeamName?.imageUrl"
              [overlayHeight]="'24px'"
              [spinnerSize]="20"
              [imageSrc]="homeTeamName.imageUrl" class="cell-image">
            </image-with-loader>
            <div class="team-color-container" *ngIf="homeTeamName.color">
              <div class="team-color-wrapper">
                <div class="team-color" [style.background-color]="homeTeamName.color"></div>
              </div>
            </div>
          </div>
          <ng-template #selectedEmptySpace>
            <div class="empty-space" *ngIf="!isShowDoneEnterIcon('homeTeam')"></div>
          </ng-template>
        </ng-container >
        <ng-template #newItemAdding>
          <small-file-loader
            [image]="this.newTeamItemImage['homeTeamId']"
            (click)="prevent($event)"
            (fileUpload)="uploadAnswerImage($event, 'homeTeamId')"
            (imageErrorMassage)="imageHomeErrorMassege = $event">
          </small-file-loader>
          <ng-container *ngIf="isShowColorPicker('homeTeam')">
              <ngx-colors
                ngx-colors-trigger
                *ngIf="!editedId"
                [formControl]="homeTeamColor"
              ></ngx-colors>
          </ng-container>
        </ng-template>

      </div>

      <div class="team-with-image">
        <div class="form-field-with-label">
          <mat-label>Away Team*</mat-label>
          <mat-form-field appearance="outline" class="autocomplete-selector-item">
            <input
              matInput
              placeholder="Select a team"
              [matAutocomplete]="awayTeamAuto"
              #awayTeamInput
              [formControlName]="'awayTeamId'"
              (input)="onAutocompleteInputEvent($event, 'awayTeam')"
              (keyup.enter)="addNewTeam($event, 'awayTeam')">
            <mat-icon matSuffix *ngIf="!isShowDoneEnterIcon('awayTeam')">arrow_drop_down</mat-icon>

            <mat-icon
              matSuffix
              *ngIf="isShowDoneEnterIcon('awayTeam')"
              (click)="addNewTeam($event, 'awayTeam')">
              done
            </mat-icon>

            <mat-autocomplete
              (closed)="onCloseAnswerSelector()"
              [displayWith]="getOptionText"
              (optionSelected)="changeTeam($event.option.value, 'awayTeam')"
              #awayTeamAuto="matAutocomplete">
              <mat-option
                *ngFor="let team of filteredTeamList|filter: eventDetailsForm.get('awayTeamId').value:'name'"
                [value]="team"
                [id]="team.id">
                <div *ngIf="team.id === editedId; else regularItem" class="edit-input">
                  <div class="div-with-error" [ngClass]="{'invalid': showEditTeamRequiredError || showEditTeamMaxLenghtError}">
                    <div class="team-input-container">
                      <input
                        (input)="onTeamInputChange(teamName)"
                        (keydown)="$event.stopPropagation()"
                        [value]="team.name"
                        #teamName
                        (click)="prevent($event)"
                        (keyup.enter)="saveTeam($event, teamName.value, 'awayTeam')">
                    </div>
                    <div class="team-edit-container">
                      <small-file-loader
                        [image]="team"
                        (click)="prevent($event)"
                        (fileUpload)="uploadAnswerImage($event)"
                        (imageErrorMassage)="imageAwayErrorMassege = $event">
                      </small-file-loader>
                      <ngx-colors
                        *ngIf="!teamsImages[editedId]?.url"
                        ngx-colors-trigger
                        (open)="onOpenColorPickerPanel()"
                        (click)="prevent($event)"
                        [formControl]="awayTeamColor"
                      ></ngx-colors>
                    </div>

                  </div>
                  <mat-error *ngIf="showEditTeamRequiredError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MANDATORY}}</span>
                  </mat-error>
                  <mat-error *ngIf="showEditTeamMaxLenghtError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MAX_LENGHT_52}}</span>
                  </mat-error>
                  <mat-error *ngIf="showEditTeamMinLenghtError">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.MIN_LENGHT_2}}</span>
                  </mat-error>
                  <mat-error *ngIf="imageAwayErrorMassege">
                    <i class="fas fa-exclamation-circle"></i>
                    <span>{{appData.BIG_IMAGE}}</span>
                  </mat-error>
                </div>
                <ng-template #regularItem>
                  <div class="mat-option-item team">
                    <ng-container>
                      <div class="image-loader-container">
                        <image-with-loader
                          *ngIf="team.imageUrl && team.id !== editedId"
                          [overlayHeight]="'24px'"
                          [spinnerSize]="20"
                          (imageErrorMassage)="imageHomeErrorMassege = $event"
                          [imageSrc]="team.imageUrl" class="cell-image">
                        </image-with-loader>
                        <div class="team-color-container" *ngIf="team.color && !team.imageUrl && team.id !== editedId">
                          <div class="team-color-wrapper">
                            <div class="team-color" [style.background-color]="team.color"></div>
                          </div>
                        </div>
                        <div class="empty-space" *ngIf="!team.color && !team.imageUrl">

                        </div>
                      </div>
                    </ng-container>
                    <div class="text-item-part">
                      {{ team.name }}
                    </div>
                    <div class="icons-part">
                      <mat-icon matSuffix class="material-icons material-icons-outlined"
                                (click)="editTeam($event, team, 'awayTeam')">edit
                      </mat-icon>
                      <mat-icon matSuffix class="material-icons material-icons-outlined"
                                (click)="deleteTeam($event, team)">delete_outline
                      </mat-icon>
                    </div>
                  </div>
                </ng-template>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error *ngIf="isShowError('awayTeamId', 'required')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MANDATORY_SELECT}}</span>
          </mat-error>
          <mat-error *ngIf="isShowAddingError('awayTeamId')">
            <i class="fas fa-exclamation-circle"></i>
            <span>{{appData.MAX_LENGHT_52}}</span>
          </mat-error>
        </div>
        <ng-container *ngIf="!isShowDoneEnterIcon('awayTeam'); else newItemAddingAway">
          <div class="image-container" *ngIf="(awayTeamName?.imageUrl) || (awayTeamName?.color); else selectedEmptySpace">
            <image-with-loader
              *ngIf="awayTeamName?.imageUrl"
              [overlayHeight]="'24px'"
              [spinnerSize]="20"
              [imageSrc]="awayTeamName.imageUrl" class="cell-image">
            </image-with-loader>
            <div class="team-color-container" *ngIf="awayTeamName.color">
              <div class="team-color-wrapper">
                <div class="team-color" [style.background-color]="awayTeamName.color"></div>
              </div>
            </div>
          </div>
          <ng-template #selectedEmptySpace>
            <div class="empty-space" *ngIf="!isShowDoneEnterIcon('awayTeam')"></div>
          </ng-template>
        </ng-container >
        <ng-template #newItemAddingAway>
          <small-file-loader
            [image]="newTeamItemImage['awayTeamId']"
            (click)="prevent($event)"
            (fileUpload)="uploadAnswerImage($event, 'awayTeamId')"
            (imageErrorMassage)="imageAwayErrorMassege = $event">
          </small-file-loader>
          <ng-container *ngIf="isShowColorPicker('awayTeam')">
            <ngx-colors
              ngx-colors-trigger
              *ngIf="!editedId"
              [formControl]="awayTeamColor"
            ></ngx-colors>
          </ng-container>
        </ng-template>
      </div>

      <div class="dates-controls">
        <custom-date-input
          formControlName="startDate">
          Start Date & Time*
        </custom-date-input>
        <custom-date-input
          *ngIf="!(profileService.currentUser$ | async)?.isStreakVendor"
          formControlName="closeDate">
          End Date & Time*
        </custom-date-input>
      </div>

      <div class="form-field-with-label">
        <mat-label>
          Event Name*
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            trimDoubleSpace
            placeholder="Name"
            [formControlName]="'name'">
        </mat-form-field>
        <mat-error *ngIf="isShowError('name', 'required')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MANDATORY}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'maxlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MAX_LENGHT_120}}</span>
        </mat-error>
        <mat-error *ngIf="!isShowError('name', 'required') && isShowError('name', 'minlength')">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{appData.MIN_LENGHT_2}}</span>
        </mat-error>
      </div>

    </form>
  </div>
  <div class="buttons">
    <button
      id="stayButton"
      class="regular-button secondary-button"
      mat-stroked-button
      color="primary"
      (click)="closeDialog()"
      mat-dialog-close>Cancel</button>
    <button
      class="regular-button"
      mat-flat-button
      color="primary"
      (click)="saveEvent()"
    >{{buttonText}}</button>
  </div>
</div>
