<div class="round-translation-container">
  <div class="round-translation-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Translations List</h2>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          color="primary"
          (click)="googleTranslate()">
          Use google translate
        </button>
        <button
          mat-raised-button
          [disabled]="isDisableFillAllButton$ | async"
          color="primary"
          (click)="fillAllLocalesByDefault()">
          Fill all empty fields with non localized content
        </button>
        <i
          class="fa-solid fa-circle-info tooltip"
          [matTooltipPosition]="TooltipPositionEnum.below"
          #tooltip="matTooltip"
          [matTooltip]="'All empty fields will be filled by non-localized text for all languages. The fields with already provided translations, won\'t be overridden'"
          [matTooltipClass]="TooltipPositionEnum.below"></i>
      </div>
    </div>
  </div>
  <div class="round-translation-body">
    <div class="round-translation-content">
      <div class="round-translation-details">
        <div class="table-wrapper">
          <div class="table-content" #container>
            <form [formGroup]="vendorDataFormGroup">
              <ng-container [formArrayName]="'items'">
                <table
                  mat-table
                  [dataSource]="formArray.controls"
                >
                  <!-- Column name Column -->
                  <ng-container [matColumnDef]="'entityValue'" [sticky]="true">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let itemFormGroup" [formGroup]="itemFormGroup">
                      {{itemFormGroup.get('entityValue').value}}
                    </td>
                  </ng-container>

                  <!-- Text Column -->
                  <ng-container [matColumnDef]="'text'">
                    <th mat-header-cell *matHeaderCellDef>Non<br/>localized</th>
                    <td mat-cell *matCellDef="let itemFormGroup" [formGroup]="itemFormGroup">
                      <div class="question-text">
                        {{itemFormGroup.get('text').value}}
                        <span class="detected" *ngIf="itemFormGroup.get('detected')?.value">
                          detected language: {{itemFormGroup.get('detected')?.value}}
                        </span>
                      </div>

                    </td>
                  </ng-container>

                  <!-- Locale translate Column -->
                  <ng-container *ngFor="let locale of locales" [matColumnDef]="locale.locale">
                    <th mat-header-cell *matHeaderCellDef>{{ locale.countryName }}</th>
                    <td mat-cell *matCellDef="let itemFormGroup"  [formGroup]="itemFormGroup">
                      <div class="form-field-with-label locale">
                        <mat-form-field appearance="outline">
                          <input
                            placeholder="Enter a translation"
                            matInput
                            trimDoubleSpace
                            trim
                            type="text"
                            [formControlName]="locale.locale">
                        </mat-form-field>
                      </div>
                    </td>
                  </ng-container>

                  <!-- Actions Column -->
                  <ng-container [matColumnDef]="'actions'">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let itemFormGroup"  [formGroup]="itemFormGroup">
                      <div class="buttons-container">
                        <button
                          mat-raised-button
                          color="primary"
                          (click)="itemFormGroup.enable();"
                          *ngIf="itemFormGroup.disabled; else save">
                          Translate
                        </button>
                        <ng-template #save>
                          <button
                            mat-raised-button
                            color="primary"
                            (click)="onSaveLocaleAction(itemFormGroup)">
                            Save
                          </button>
                        </ng-template>
                      </div>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="getColumns(); sticky:true"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: getColumns();"
                    [ngClass]="{'unsaved': (closeButtonClicked$ | async) && row.pristine && row.enabled}">
                  </tr>
                </table>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
