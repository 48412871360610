<wizzard-section>
  <div header>PRIZES</div>
  <div body>
 <!--   <div class="action-area" *ngIf="displayPrizeSumControl">
      <mat-slide-toggle
        color="primary"
        [disabled]="(predictionPrizeSectionService.roundPrizeType$ | async) === roundPrizeTypeEnum.LABEL"
        [checked]="displayPrizeSumControl?.value"
        (change)="changeDisplayPrizeSumControl($event)">
        Sum of prize amount
      </mat-slide-toggle>
    </div>-->

    <div class="form-field-with-label" >
      <mat-label>Select prize type</mat-label>
      <mat-radio-group
        color="primary"
        [value]="predictionPrizeSectionService.roundPrizeType$ | async"
        [disabled]="roundHelperService.isRoundClosed()"
        (change)="onPrizeTypeSelection($event.value)">
        <mat-radio-button
          *ngFor="let type of prizeOptions"
          [value]="type.value">
          {{type.label}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngFor="let prizeItem of prizes.controls; let i = index">
      <ng-container [formGroup]="prizeItem">
        <prize-item
          [index]="i"
        >
        </prize-item>
      </ng-container>
    </ng-container>
  </div>
</wizzard-section>

