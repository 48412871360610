import { Pipe, PipeTransform } from '@angular/core';
import {AnswerService} from "../../../core/services/answer.service";

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  constructor(
    private answerService: AnswerService
  ) {
  }

  transform(value: any[], search: string, field: string, include = true): any[] {
    if (typeof search !== 'string') return value;
    const formatedSearch = search.trim().toLowerCase();
    if (!formatedSearch) {
      return value;
    }

    if (include) {
      const filtered = value.filter((v) =>
        v[field].toLowerCase().includes(formatedSearch)
      )
      if (field === 'text') {
        //eslint-disable-next-line
        this.answerService.setAnswerItemStatus(!(!!filtered.length));
      }
      return filtered
    } else {
      return value.filter((v) =>
        !v[field].toLowerCase().includes(formatedSearch)
      );
    }

  }

}
