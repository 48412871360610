import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from "../../_base-component/dialog/dialog.service";

@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {

  constructor(
    private dialogService: DialogService
  ) {
  }
  closeModal() {
    this.dialogService.closeModal();
  }
}
