import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {AnswerDetailsModel} from "../../common/models/AnswerDetailsModel";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  isNewItemEntered$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  getAnswersList(body): Observable<AnswerDetailsModel[]> {
    let params = new HttpParams();
    Object.keys(body).forEach(param => {
      params = params.set(param, body[param]);
    });
    return this.http.get<AnswerDetailsModel[]>(this.uriConfig.answers, {params});
  }

  getExternalAnswersList(externalSportEventId): Observable<AnswerDetailsModel[]> {
    return this.http.get<AnswerDetailsModel[]>(this.uriConfig.answers + '/external/' + externalSportEventId);
  }

  createNewAnswers(answer): Observable<AnswerDetailsModel> {
    return this.http.post<any>(this.uriConfig.answers, answer);
  }

  deleteAnswersById(answer: AnswerDetailsModel) {
    const {text, id} = answer
    const body = {
      text,
      isHidden: true
    }
    return this.http.patch<any>(this.uriConfig.answers + '/' + id, body);
  }

  updateAnswer(body, id) {
    return this.http.patch<any>(this.uriConfig.answers + '/' + id, body);
  }

  bulkSubmitCorrectAnswers(correctAnswers) {
    return this.http.post(this.uriConfig.correctAnswers, correctAnswers);
  }

  undoSubmittedResults (roundId: number, questionIds?: number[]) {
    const body: {roundId: number, questionIds?: number[]} = {
      roundId: roundId
    }
    if (questionIds) {
      body.questionIds = questionIds;
    }
    return this.http.patch(this.uriConfig.answers + '/reset-submissions', body);
  }

  checkAnswerMatches(body):Observable<{ questionIdsThatNotMatch: string[] }> {
    return this.http.post<{ questionIdsThatNotMatch: string[] }>(this.uriConfig.answers + '/check', body);
  }

  setAnswerItemStatus(status) {
    this.isNewItemEntered$.next(status);
  }
}
