
<div
  class="form-field-with-label"
  [ngClass]="{valid: (control.touched && control.valid || control.disabled && control.value)}">
  <div #ref>
    <mat-label>
      <ng-content></ng-content>
      <i
        *ngIf="tooltipsText"
        class="fa-solid fa-circle-info"
        [matTooltipPosition]="tooltipPosition"
        #tooltip="matTooltip"
        [matTooltip]="tooltipsText"
        [matTooltipClass]="tooltipPosition"></i>
    </mat-label>
  </div>
  <span *ngIf=" ! ref.children.length"></span>
    <mat-form-field appearance="outline" [ngClass]="{'small-form': isSmall}">
      <input
        matInput
        trim
        trimDoubleSpace
        onlyInteger
        min="0"
        [ignoreTrim]="isIgnoreTrim"
        [type]="type"
        [value]="value$ | async"
        [attr.disabled]="disabled$ | async"
        [placeholder]="placeholder"
        [formControl]="control"
        (focusout)="markAsTouched()"
        (input)="valueChanged($event)">
    </mat-form-field>
    <mat-error *ngFor="let error of errors$  | async">
      <i class="fas fa-exclamation-circle"></i>
      <span>{{error}}</span>
    </mat-error>
  </div>
