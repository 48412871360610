<div class="edit-date-container">    
    <div class="edit-date-header body-medium-medium">Edit Round Close Date</div>
    <div class="edit-date-body">
      <form [formGroup]="groupForm">
        <custom-date-input
          formControlName="closeDate"
          tooltipsText="The time and date the round will close for entries"
          pickerType="both">
          Close Date & Time*
        </custom-date-input>
      </form>
    </div>
    <div class="buttons">
      <button
        id="stayButton"
        class="regular-button secondary-button"
        mat-stroked-button
        color="primary"
        mat-dialog-close>
        Cancel
    </button>
      <button
        class="regular-button"
        mat-flat-button
        color="primary"
        [disabled]="groupForm.invalid && groupForm.touched"
        (click)="saveDate()">
        Save
    </button>
    </div>
  </div>
  