import {
  ChangeDetectionStrategy, Component,
  Inject, Injector, Input, OnDestroy, OnInit
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ValidationErrors
} from "@angular/forms";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { getErrorFromValidator } from "../../../modules/validators/validator-text.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";

@Component({
  selector: 'custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent
    }
  ]
})
export default class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  @Input() tooltipsText: string;

  @Input() isIgnoreTrim: boolean;

  @Input() tooltipPosition = TooltipPositionEnum.right

  @Input() placeholder = '';

  @Input() type = '';

  @Input() isSmall = false;

  @Input()
  set groupNameError(error: string) {
    if (error) {
      this.errors$.next([error]);
    }
  }

  errors$ = new BehaviorSubject([]);

  value$ = new BehaviorSubject('');

  touched = false;

  disabled$ = new BehaviorSubject(false);

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  constructor (
    @Inject(Injector) private injector: Injector
  ) {}

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}

    if (this.control.touched) {
      this.validate();
    }

    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.validate();
      });
  }

  valueChanged($event) {
    this.onChange($event.target.value);
    this.validate();
  }

  validate() {
    const errors = [];
    if (this.control?.errors) {
      Object.keys(this.control.errors as ValidationErrors).forEach(key => {
        if(this.control.errors[key]) {
          errors.push(getErrorFromValidator(key, this.control.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }

  writeValue(value: any): void {
    this.value$.next(value);
    if (this.control.touched) {
      this.validate();
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.validate();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
