import { Injectable } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { RoundSportEnum } from 'src/app/common/Enums/RoundSportEnum';
import { RoundStatusEnum } from 'src/app/common/Enums/RoundStatusEnum';
import { RoundTypesEnum } from 'src/app/common/Enums/RoundTypesEnum';
import {
  CreateRoundMainDetailsFormInterface
} from 'src/app/common/FormsModels/create-round-main-details-form-interface';
import { RoundDetailsModel } from 'src/app/common/models/RoundDetailsModel';
import { IsCloseDateAfterOpen } from 'src/app/new-round-wizzard/wizzard-sections/details-section/date-validator';
import { openDateValidator } from '@modules/validators/open-date-validator';
import { closeDateValidator } from '@modules/validators/close-date-validator';

@Injectable()
export class DetailsFormSectionService {

  sportTypes = Object.keys(RoundSportEnum);

  constructor(
    private fb: NonNullableFormBuilder,
    private isCloseDateAfterOpen: IsCloseDateAfterOpen,
  ) {}

  fillForm(form: FormGroup<CreateRoundMainDetailsFormInterface>, round: RoundDetailsModel, updateDate = true) {
    form.markAllAsTouched();
    form.get('name').patchValue(round.name);
    if (updateDate) {
      form.get('closeDate').patchValue(round.closeDate);
      form.get('openDate').patchValue(round.openDate);
    }
    form.get('description').patchValue(round.description);
    form.get('type').patchValue(round.type);
    form.get('displayPrizeSum').patchValue(round.displayPrizeSum);
		form.get('visibleForUsers').patchValue(round.visibleForUsers);
    form.get('ticketRequired').patchValue(round.ticketRequired);
    form.get('bonus').patchValue(round.bonus);
    form.get('type').disable();

    if (round?.sportEvent?.id) {
      form.get('sportEvent').patchValue(round.sportEvent);
      form.get('sportEvent').disable();
    }

    if (round.backgroundImageDesktopUrl) {
      form.get('backgroundImageDesktopUrl').patchValue(round.backgroundImageDesktopUrl);
    }

    if (round.backgroundImageMobileUrl) {
      form.get('backgroundImageMobileUrl').patchValue(round.backgroundImageMobileUrl);
    }

    if (round.imageUrl) {
      form.get('imageUrl').patchValue(round.imageUrl);
    }

    if (!(round.status === RoundStatusEnum.PENDING || round.status === RoundStatusEnum.DRAFT || round.status === RoundStatusEnum.OPENED)) {
      form.disable();
    }
    if (!(round.type === RoundTypesEnum.Other)) {
      const sportIndex = this.sportTypes.findIndex(type => type.toLowerCase() === round.sport[0].toLowerCase());
      // @ts-ignore
      form.get('sport').patchValue(this.sportTypes[sportIndex]);
    } else {
      form.get('sport').removeValidators(Validators.required);
      // @ts-ignore
      form.get('sport').patchValue('');
    }
    if(round.status === RoundStatusEnum.OPENED) {
      form.get('openDate').disable();
      form.get('sport').disable();
      form.get('type').disable();
      form.get('visibleForUsers').disable();
      form.get('ticketRequired').disable();
    }
    form.updateValueAndValidity();
    return form;
  }

  fillEventField (form, sportEvent){
    form.get('sportEvent').patchValue(sportEvent);
    form.get('sportEvent').disable();
  }


  buildForm(): FormGroup<CreateRoundMainDetailsFormInterface> {
    // @ts-ignore
    return this.fb.group({
        name: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(52),
          (control) => {
            const includeDoubleSpace = /\s{2}/.test(control.value)
            if (includeDoubleSpace) {
              return {
                doubleSpace: {value: control.value}
              }
            } else {
              return null
            }
          }]),
        description: new FormControl('', [Validators.minLength(2), Validators.maxLength(255)]),
        openDate: new FormControl({value:'', disabled: false},
          Validators.compose([Validators.required, openDateValidator(false)])
        ),
        imageUrl: new FormControl(null),
        closeDate: new FormControl('', [Validators.required, closeDateValidator()]),
        sport: new FormControl(undefined, [Validators.required]),
        type: new FormControl({value:RoundTypesEnum.Sport, disabled: false}, [Validators.required]),
        backgroundImageDesktopUrl: new FormControl(null),
        backgroundImageMobileUrl: new FormControl(null),
        sportEvent: new FormControl({value:null, disabled: false}),
        displayPrizeSum: new FormControl(false),
				visibleForUsers: new FormControl(true),
				ticketRequired: new FormControl(false),
        bonus: new FormControl(null),
      },
      {
        validators: [this.isCloseDateAfterOpen.validateDates()],
      }
    );
  }
}
