import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[onlyInteger]'
})
export class OnlyIntegerDirective {

  value: string;

  constructor(
    public ref: ElementRef,
    public ngControl: NgControl) {
  }

  @HostListener('input', ['$event']) onInput($event) {
    const value = $event.target.value;
    const type =  $event.target.type;
    let updateValue = value;
    if (type === 'number' && /[.,]/.test(value)) {
      //eslint-disable-next-line
      updateValue = value.replace(/[,\.]/g, '');
    }
    this.setUpTrimValue($event, updateValue);
  }

  setUpTrimValue ($event, value) {
    $event.target.value = value;
    if (this.ngControl.name) {
      this.ngControl.control.setValue(value);
    }

    this.value = value;
    $event.preventDefault();
  }

}
