<div
        class="form-field-with-label">
    <div class="body-small-medium input-label">
        <ng-content></ng-content>
        <i
                *ngIf="tooltipText"
                class="fa-solid fa-circle-info"
                [matTooltipPosition]="TooltipPositionEnum.right"
                #tooltip="matTooltip"
                [matTooltip]="tooltipText"
                [matTooltipClass]="TooltipPositionEnum.right"></i>
    </div>

    <div class="dropdiv"
         *ngIf="!((disabled$ | async) && (url$ | async))"
         [ngClass]="{disabled: (disabled$ | async), 'with-error': (isImageTooBig$ | async) || (isMandatoryError$ | async)}"
         (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
        <label class="custom-file-upload">
            <input (change)="onChange($event)" type="file" onclick="this.value=null" [disabled]="disabled$ | async"/>
            <img src="../../../../../assets/images/icons/upload.svg">
            <div class="drag-text">
                Drag and drop or browse to choose a file
            </div>
        </label>
        <div *ngIf="isLoading$ | async" class="disabled-overlay">
            <mat-spinner [diameter]="30" color="primary"></mat-spinner>
        </div>
    </div>
    <mat-error *ngIf="isImageTooBig$ | async">
        <i class="fas fa-exclamation-circle"></i>
        <span>{{appData.BIG_IMAGE}}</span>
    </mat-error>
    <mat-error *ngIf="(isMandatoryError$ | async)">
        <i class="fas fa-exclamation-circle"></i>
        <span>{{appData.MANDATORY_IMAGE}}</span>
    </mat-error>

    <ng-container *ngIf="(url$ | async) as url">
        <div class="image-uploaded-line">
            <div class="image-info-container">
                <div class="image-info">
                    <image-with-loader
                            [overlayHeight]="'24px'"
                            [spinnerSize]="20"
                            [imageSrc]="url"
                            class="cell-image">
                    </image-with-loader>
                    <div class="image-size">{{(imageSize$ | async) + (imageSizeSymbol$ | async)}}</div>
                </div>
                <div class="image-control">
                    <mat-icon *ngIf="!(disabled$ | async)" (click)="deleteImage()"
                              class="material-icons material-icons-outlined">clear
                    </mat-icon>
                </div>
            </div>
        </div>
    </ng-container>
</div>
