import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from '@angular/forms';
import { QuestionDetailsModel, VoidType } from "../../../common/models/QuestionDetailsModel";
import {
  EventDetailsFormInterface,
} from "../../../common/FormsModels/correct-answers-details-form-interface";
import { TextService } from "../../../core/services/text.service";
import { BehaviorSubject, lastValueFrom, tap } from "rxjs";
import { decimalOrNegativeValidator } from "../../../common/modules/validators/decimal-or-negative-validator";
import { CorrectAnswerHelperService } from "../../../core/services/streak/correct-answer-helper.service";

@Injectable({
  providedIn: 'root'
})

export class CorrectAnswerPredictionServiceService {

  correctEventForm: FormGroup<EventDetailsFormInterface>;

  voidTypeOptions: { value: VoidType, label: string }[];

  isEventFormValid$ = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: NonNullableFormBuilder,
    private textService: TextService,
    private correctAnswerHelperService: CorrectAnswerHelperService
  ) {
    this.voidTypeOptions = Object.values(VoidType)
      .map(value => {
        return {
          value,
          label: value === 'NO_RESULT' ? 'Question Void' : this.textService.createNameWithSpaceAndCapitalizeFirstLetter(value)
        }
      });
  }

  formProcessing(questionList: QuestionDetailsModel[]) {
    return this.correctAnswerHelperService.formProcessing(questionList);
  }

  buildEventForm(sportEvent) {
    const score = this.correctAnswerHelperService.getScore(sportEvent.score);
    this.correctEventForm = this.fb.group({
      homeTeam: new FormControl(score[0], [Validators.required, decimalOrNegativeValidator()]),
      awayTeam: new FormControl(score[1], [Validators.required, decimalOrNegativeValidator()]),
      id: new FormControl(sportEvent.id)
    });
    this.isEventFormValid$.next(this.correctEventForm.valid);
    this.detectEventChanges();
    return this.correctEventForm;
  }

  async detectEventChanges() {
    await lastValueFrom(this.correctEventForm.valueChanges.pipe(
      tap(() => {
        this.eventFormValidation()
      })
    ));
  }

  eventFormValidation() {
    this.isEventFormValid$.next(this.correctEventForm.valid)
  }
}
