import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HTTP_INTERCEPTORS
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {SnackBarService} from "../services/snack-bar.service";
import {LocalStorageService} from "../services/local-storage.service";
import { TwoFaAuthService } from "../services/two-fa-auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: LocalStorageService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private twoFaAuthService: TwoFaAuthService
  ) {}

  /**
   * Listen for the error api response. If error is 401 then check adress.
   * If andress include "refresh" then logout.
   * If no include, send api with refresh token to get new accessToken
   * Is error is not 401 just return error
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && !req.url.endsWith('logout') && !this.twoFaAuthService.isTwoFaInProgress$.value ) {
        if (!req.url.endsWith('refresh')) {
          return this.handle401Error(req, next);
        } else {
          this.authService.logOut();
          this.snackBarService.showSnackBar('Your session is expired');
        }
      } else {
        return throwError(error);
      }
    }));
  }

  /**
   * Create headers with refresh token and send refresh request.
   * When new get new tokens, set it to the local storage and resend request
   * @param request
   * @param next
   * @private
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.tokenService.getRefreshToken();

      const authReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        },
      });

      return this.authService.refreshToken(authReq).pipe(
        switchMap((token: any) => {

          this.isRefreshing = false;

          this.tokenService.setToken(token.accessToken);
          this.tokenService.setRefreshToken(token.refreshToken);
          this.refreshTokenSubject.next(token);

          const authReq = request.clone({
            headers: request.headers.set(
              'Authorization',
              'Bearer ' + token.accessToken
            ),
          });

          return next.handle(authReq);
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.tokenService.clear();
          window.location.href = '/';
          return throwError(err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => {
        const authReq = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + token.accessToken
          ),
        });
        return next.handle(authReq)
      })
    );
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
