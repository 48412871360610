import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import { RoundDetailsModel } from "../common/models/RoundDetailsModel";
import { RoundStatusEnum } from "../common/Enums/RoundStatusEnum";
import { RoundTypesEnum } from "../common/Enums/RoundTypesEnum";
import { RoundClassEnum } from "../common/Enums/RoundClassEnum";
import { EventDetailsModel } from "../common/models/EventDetailsModel";

@Injectable()
export class RoundHelperService {

  private curentRoundBS = new BehaviorSubject<RoundDetailsModel>(undefined);

  public curentRound$ = this.curentRoundBS.asObservable();

  set curentRound(round: RoundDetailsModel) {
    this.curentRoundBS.next(round);
    this.roundClass = round?.class;
    this.roundType = round?.type;
    this.singleRoundEvent = round?.sportEvent;
  }

  get curentRound() {
    return this.curentRoundBS.value;
  }

  //-----------------------------

  private singleRoundEventBS = new BehaviorSubject<EventDetailsModel>(null);

  set singleRoundEvent(value: EventDetailsModel) {
    this.singleRoundEventBS.next(value);
  }

  get singleRoundEvent() {
    return this.singleRoundEventBS.value;
  }

  public singleRoundEvent$ = this.singleRoundEventBS.asObservable();

  //-----------------------------

  private _roundTryToSave$ = new BehaviorSubject<boolean>(false);

  set roundTryToSave(value: boolean) {
    this._roundTryToSave$.next(value);
  }

  get roundTryToSave() {
    return this._roundTryToSave$.value;
  }

  //------------------------------

  private roundClassBS = new BehaviorSubject<RoundClassEnum>(undefined);

  set roundClass(value: RoundClassEnum) {
    this.roundClassBS.next(value);
  }

  get roundClass() {
    return this.roundClassBS.value;
  }

  roundClass$ = this.roundClassBS.asObservable();

  //-----------------------------

  private roundTypeBS = new BehaviorSubject<RoundTypesEnum>(undefined);

  set roundType(value: RoundTypesEnum) {
    this.roundTypeBS.next(value);
  }

  get roundType() {
    return this.roundTypeBS.value;
  }

  roundType$ = this.roundTypeBS.asObservable();

  //------------------------------

  roundStatus$ = this.curentRound$.pipe(
    map(round => round?.status)
  );

  get prize() {
    return this.curentRound?.prize;
  }

  get questions() {
    return this.curentRound?.questions;
  }

  get sportEvent() {
    return this.curentRound?.sportEvent;
  }

  get roundStatus() {
    return this.curentRound?.status;
  }

  get roundId() {
    return this.curentRound?.id;
  }

  sportEvent$ = this.curentRound$.pipe(
    map(round => round?.sportEvent)
  );

  isFinishedRound$ = this.curentRound$.pipe(
    map(round =>(
      round?.status === RoundStatusEnum.CLOSED ||
      round?.status === RoundStatusEnum.COMPLETED ||
      round?.status === RoundStatusEnum.RESULT_PROCESSING
    ))
  );

  isShowCloseRoundMassage$ = this.curentRound$.pipe(
    map(round => (
      round?.status === RoundStatusEnum.CLOSED &&
      (
        round?.type === RoundTypesEnum.Other ||
        round?.type === RoundTypesEnum.Sport
      )
    ))
  );

  get isMultiEventRound() {
    return this.roundClass === RoundClassEnum.Multi;
  }

  isMultiEventRound$ = this.roundClass$.pipe(
    map(roundClass => roundClass === RoundClassEnum.Multi)
  );

  isRoundClosed() {
    return this.roundStatus === RoundStatusEnum.CLOSED ||
      this.roundStatus === RoundStatusEnum.COMPLETED ||
      this.roundStatus === RoundStatusEnum.RESULT_PROCESSING;
  }

}
