<div class="popup-header-container">
  <div class="popup-header-wrapper">
    <div class="info-container">
      <mat-icon class="material-icons close" (click)="closeModal()">close</mat-icon>
      <div class="header-name">
        <ng-content select="[header-name]"></ng-content>
      </div>
      <ng-content select="[chips]"></ng-content>
    </div>
    <div class="buttons-container">
      <ng-content select="[buttons-set]"></ng-content>
    </div>
  </div>
</div>
