export enum RoundSportEnum {
  Soccer= 'SOCCER',
  Golf= 'GOLF',
  Tennis= 'TENNIS',
  Rugby= 'RUGBY',
  Racing = 'RACING',
  Baseball = 'BASEBALL',
  Cricket = 'CRICKET',
  Basketball = 'BASKETBALL',
}
