import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { CreateRoundPrizeFormInterface } from "../../../common/FormsModels/create-round-prize-form-interface";
import { PredictionPrizeSectionService } from "./prize-section.service";
import { RoundPrizeTypeEnum } from "../../../common/Enums/RoundPrizeTypeEnum";
import { TextService } from "../../../core/services/text.service";
import { RoundHelperService } from "../../round-helper.service";
import { Subject } from "rxjs";

@Component({
  selector: 'prediction-prize-section',
  templateUrl: './prediction-prize-section.component.html',
  styleUrls: ['./prediction-prize-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PredictionPrizeSectionComponent implements OnInit, OnDestroy {

  roundPrizeForm: FormGroup<CreateRoundPrizeFormInterface>;

  @Input() displayPrizeSumControl: FormControl;

  sharedPrizePotControl: FormControl = new FormControl<boolean>(true);

  prizeOptions = [
    {
      label: this.textService.createNameWithSpaceAndCapitalizeFirstLetter(RoundPrizeTypeEnum.CASH_POINTS),
      value: RoundPrizeTypeEnum.CASH_POINTS
    },
    {
      label: this.textService.createNameWithSpaceAndCapitalizeFirstLetter(RoundPrizeTypeEnum.LABEL),
      value: RoundPrizeTypeEnum.LABEL
    }
  ];

  roundPrizeTypeEnum = RoundPrizeTypeEnum;

  private unsubscribe$ = new Subject<void>()

  constructor(
    private rootFormGroup: FormGroupDirective,
    private textService: TextService,
    public roundHelperService: RoundHelperService,
    public predictionPrizeSectionService: PredictionPrizeSectionService,
  ) {
  }

  ngOnInit(): void {
    this.roundPrizeForm = this.rootFormGroup.control;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get prizes(): FormArray {
    return this.roundPrizeForm.get('prizes') as FormArray;
  }

  onPrizeTypeSelection(value) {
    if (value === RoundPrizeTypeEnum.LABEL) {
      this.displayPrizeSumControl.patchValue(false);
      this.sharedPrizePotControl.patchValue(false);
    } else {
      this.displayPrizeSumControl.patchValue(true);
      this.sharedPrizePotControl.patchValue(true);
    }
    this.predictionPrizeSectionService.roundPrizeType$.next(value);
    this.validateForm(value);
  }

  validateForm(selectedValue) {
    const requiredFiled = selectedValue === RoundPrizeTypeEnum.LABEL ? 'prize' : 'cashPoints';
    const oldFiled = selectedValue === RoundPrizeTypeEnum.LABEL ? 'cashPoints' : 'prize';
    this.predictionPrizeSectionService.prizes.controls.forEach(formGroup => {
      formGroup.get(oldFiled).setErrors(null);
      formGroup.get(requiredFiled).addValidators([Validators.required]);
      formGroup.get(requiredFiled).updateValueAndValidity();
    })
  }

}
