import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrimDirective} from "./directives/trim.directive";
import { TrimDoubleSpaceDirective } from './directives/trim-double-space.directive';
import { ScrollControlDirective } from "./directives/scroll-control.directive";
import { OnlyIntegerDirective } from './directives/only-integer.directive';


@NgModule({
  declarations: [
    TrimDirective,
    TrimDoubleSpaceDirective,
    ScrollControlDirective,
    OnlyIntegerDirective
  ],
    exports: [
      TrimDirective,
      TrimDoubleSpaceDirective,
      ScrollControlDirective,
      OnlyIntegerDirective
    ],
  imports: [
    CommonModule,
  ]
})
export class DirectivesModule {
}
