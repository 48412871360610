import { Component } from '@angular/core';

@Component({
  selector: 'wizzard-massage',
  templateUrl: './wizzard-massage.component.html',
  styleUrls: ['./wizzard-massage.component.scss']
})
export class WizzardMassageComponent {

}
