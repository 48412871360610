import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig
  ) { }

  uploadImage(file: any) {
    return this.http.post<any>(this.uriConfig.files + '/image', file);
  }

  downLoadFileByLink(link: string) {
    const a =  document.createElement('a');
    document.body.appendChild( a)
    a.href = link;
    a.download = '';
    a.click();
    document.body.removeChild(a);
  }
}
