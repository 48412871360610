import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormArray, FormGroup, ValidationErrors } from "@angular/forms";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  throwError,
} from "rxjs";
import { DialogService } from "src/app/common/components/_base-component/dialog/dialog.service";
import { AnswerDetailsModel } from "src/app/common/models/AnswerDetailsModel";
import { getErrorFromValidator } from "src/app/common/modules/validators/validator-text.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { PredictionQuestionFormSectionService } from "../../prediction-question-section-form.service";
import { QuestionTypesEnum } from "src/app/common/Enums/QuestionTypesEnum";
import { RounWizzardAnswerService } from "../../round-wizard-answer.service";
import { AnswerGroupsService } from "../../answer-groups.service";
import { APP_DATA } from "../../../general.app.config";
import { MoveDirectionEnum } from "../../../common/Enums/MoveDirectionEnum";
import { RoundProcessingService } from "../../../core/services/round-processing.service";
import { EventDetailsModel } from "../../../common/models/EventDetailsModel";

interface AnswerConfig  {
  controlName: string,
  title: string,
  controlList: FormGroup[]
}

@Component({
  selector: 'answers-question',
  templateUrl: './answers-question.component.html',
  styleUrls: ['./answers-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswersQuestionComponent implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  isExternalAnswersAvailable = true;

  @Input() set maxAnswerLength(value) {
    this.maxAnswerLength$.next(value);
  }

  @Input() fromModalWindow = false;

  questionType: any;

  @Input() set _questionType(type) {
    this.questionType = type;
    this.generateAnswerList();
  }

  event: EventDetailsModel;


  @Input() set _isExternalEvent(externalEvent) {
    this.event = externalEvent;
    this.checkExternalAnswers();
    this.generateAnswerList();
  }

  @Input() answerGroupId = 0;

  @Input() answerGroupId2 = 0;

  @Input() hideImage = false;

  @Input() titleFor2ndList = '2st Answers*';

  @Input() set form(form) {
    if (form) {
      this.answersForm = form;

      this.answerConfigList$ = this.answersForm.valueChanges
        .pipe(
          startWith(this.answersForm.getRawValue()),
          takeUntil(this.unsubscribe$),
          map((answersFormValue) => {
            return Object.keys(answersFormValue).map(controlName => {
              let title = 'Answers*';
              if (controlName === 'answers1st')  {
                title = '1st Answers*';
              } else if (controlName === 'answers2st') {
                title = this.titleFor2ndList;
              }
              const controlList = answersFormValue[controlName].map((answerFormValue, index) => {
                return (this.answersForm.controls?.[controlName] as FormArray)?.controls?.[index];
              });
              return {
                controlName,
                title,
                controlList,
                errors: this.validate(controlName)
              };
            })
          })
        );


      if (this.answersForm.controls['answers2st']) {
        this.answersForm.controls['answers2st'].valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          map(() => 'answers2st')
        ).subscribe((controlName) => this.currentEditableAnswers = controlName)
      }

      if (this.answersForm.controls['answers']) {
        this.answersForm.controls['answers'].valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          map(() => 'answers')
        ).subscribe((controlName) => this.currentEditableAnswers = controlName)
      }

      if (this.answersForm.controls['answers1st']) {
        this.answersForm.controls['answers1st'].valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          map(() => 'answers1st')
        ).subscribe((controlName) => this.currentEditableAnswers = controlName)
      }
    }
  }

  @Output() onAddForm = new EventEmitter<void>();

  @Output() isAnswersDirty = new EventEmitter<boolean>();

  @Output() isAnswersDirty2st = new EventEmitter<boolean>();

  maxAnswerLength$ = new BehaviorSubject<number>(undefined);

  errors$ = new BehaviorSubject<any>(undefined);

  touched$ = new BehaviorSubject(false);

  answersForm: FormGroup;

  answerList$= new BehaviorSubject(null);

  answerConfigList$: Observable<AnswerConfig[]>;

  currentEditableAnswers = '';

  APP_DATA = APP_DATA

  constructor (
    private rounWizzardAnswerService: RounWizzardAnswerService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private questionFormSectionService: PredictionQuestionFormSectionService,
    private roundProcessingService: RoundProcessingService,
    public answerGroupsService: AnswerGroupsService
  ) {}

  changeOrderEvent(direction: MoveDirectionEnum, index: number, controlName: string) {
    this.shiftItemInForm(this.answersForm.controls[controlName]['controls'], index, direction);
    this.answersForm.controls[controlName].markAsDirty();
  }

  async generateAnswerList() {
    this.rounWizzardAnswerService.answerList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(answerList => {
        if (answerList) {
          this.answerList$.next(this.rounWizzardAnswerService.isExternalAnswerNeed(this.questionType) ? this.filterAnswerList(answerList) : answerList.filter(item => !item.isExternal));
        }
      });
  }

  async checkExternalAnswers() {
    this.isExternalAnswersAvailable = await firstValueFrom(this.roundProcessingService.isDownloadingAnswersAvailable());
  }

  filterAnswerList(answerList) {
    return answerList.filter(answer => {
      if (!answer.externalData) return true;
      return +answer.externalData.eventId === this.event.externalData?.eventId;
    });
  }


  shiftItemInForm(formGroups: FormGroup[], index: number, direction: MoveDirectionEnum) {
    if (direction === MoveDirectionEnum.UP) {
      const valueForShiftUp = formGroups[index - 1].value;
      formGroups[index - 1].patchValue(formGroups[index].value);
      formGroups[index].patchValue(valueForShiftUp);
    } else {
      const valueForShiftDown = formGroups[index + 1].value;
      formGroups[index + 1].patchValue(formGroups[index].value);
      formGroups[index].patchValue(valueForShiftDown);
    }
  }


  addByType(controlName: string) {
    const type = this.answersForm.parent?.get('type') ? this.answersForm.parent?.get('type').value : this.questionType;
    switch (type) {
      case QuestionTypesEnum.OPTIONS:
        this.add(
          controlName,
          (this.answersForm.controls[controlName] as FormArray),
          2
        );
        break;
      case QuestionTypesEnum.LIST:
      case QuestionTypesEnum.LISTS:
        this.add(
          controlName,
          (this.answersForm.controls[controlName] as FormArray),
          15
        );
        break;
      case QuestionTypesEnum.GRID:
        this.add(
          controlName,
          (this.answersForm.controls[controlName] as FormArray),
          30
        );
        break;
    }
  }

  getFilteredAnswerList(answerList, control) {
    const selectedAnswers = this.answersForm.getRawValue();
    const result = answerList.filter(answer =>
      answer.id === control.get('id').value ||
      Object.keys(selectedAnswers).reduce((isAnswerValid, answerKey) => {
        selectedAnswers[answerKey].forEach(selectedAnswer => {
          if (this.isAnswerSelected(selectedAnswer, answer)) {
            isAnswerValid = false;
          }
        })
        return isAnswerValid;
      }, true)
    );
    return result;
  }

  isAnswerSelected(selectedAnswer, answer) {
    if (selectedAnswer.id === answer.id) return true;
    if (selectedAnswer.externalData && answer.externalData.outcomeId && selectedAnswer.externalData.outcomeId === answer.externalOutcomeId) return true;
    if (selectedAnswer.externalData && answer.externalData && selectedAnswer.externalData.outcomeId === answer.externalData.outcomeId) return true;
    return false;
  }

  add(controlName: string, formArray: FormArray, maxLength: number) {
    if (formArray.length <= maxLength) {
      formArray
        .push(this.questionFormSectionService.buildAutocompleteQuestion());
      if (formArray.length == maxLength) {
        this.snackBarService.showSnackBar(
          `You have added ${maxLength} of  ${maxLength} available answers to the ${controlName.toLocaleLowerCase().replace('*', '')} list`
        );
      }
      formArray.markAsDirty();
    }
  }

  remove(index: number, controlName: string) {
    const answerId = (this.answersForm.controls[controlName] as FormArray).controls[index].value?.id;
    if (answerId) {
      this.removeAnswerFromExisting(answerId);
    }

    (this.answersForm.controls[controlName] as FormArray).removeAt(index);
    this.answersForm.markAsTouched();
    if ((this.answersForm.controls[controlName] as FormArray).controls[0]) {
      (this.answersForm.controls[controlName] as FormArray).controls[0].patchValue(
        (this.answersForm.controls[controlName] as FormArray).controls[0].value
      );
    }
    this.answersForm.controls[controlName].markAsDirty();

    this.checkIsAnswersAllowed();
  }

  async removeAnswerFromExisting(answerId) {
    const usedAnswers = await firstValueFrom(this.answerGroupsService.existingAnswersId$);
    if (usedAnswers && usedAnswers.length) {
      const index = usedAnswers.indexOf(answerId);
      if (index > -1) {
        usedAnswers.splice(index, 1);
        this.answerGroupsService.existingAnswersId$.next(usedAnswers);
      }
    }
  }

  validate(controlName) {
    const errors = [];
    if (this.answersForm.touched) {
      if (this.answersForm.controls[controlName]?.errors) {
        Object.keys(this.answersForm.controls[controlName]?.errors as ValidationErrors).forEach(key => {
          if(this.answersForm.controls[controlName]?.errors[key]) {
            errors.push(getErrorFromValidator(key, this.answersForm.controls[controlName]?.errors[key]));
          }
        });
      }
    }
    return errors;
  }

  touched() {
    if (!this.touched$.value) {
      this.touched$.next(false);
      this.answersForm.markAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async createAnswer(answer: AnswerDetailsModel, form: FormGroup, fromEdit = false) {
    const newAnswer = answer.isExternalApi ? {text: answer.text, externalData: answer.externalData} : answer;
    const createdAnswer = await lastValueFrom(
      this.rounWizzardAnswerService.createNewAnswers(newAnswer)
        .pipe(
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
    await firstValueFrom(this.rounWizzardAnswerService.getAnswersList(this.event, this.isExternalAnswersAvailable));
    if (!fromEdit) {
      this.applyAnswer(createdAnswer, form);
    }
  }

  setAnswer(answer: AnswerDetailsModel, form: FormGroup) {
    if (answer.isExternalApi) {
      this.applyAnswer(answer, form);
      this.createAnswer(answer, form);
    } else {
      this.applyAnswer(answer, form);
    }
  }

  applyAnswer(answer: AnswerDetailsModel, form: FormGroup) {
    const oldAnswerId = form.value?.id;
    if (oldAnswerId) {
      this.removeAnswerFromExisting(oldAnswerId);
    }

    Object.keys(answer).forEach(key => {
      if (key === "externalData" && answer[key]) {
        form.get('externalOutcomeId').patchValue(answer[key].outcomeId);
        form.markAsDirty();
      }
      if (form.get(key)) {
        form.get(key).patchValue(answer[key]);
        form.markAsDirty();
      }
    });

    if (!this.fromModalWindow) {
      this.checkIsAnswersAllowed();
    }
  }

  checkIsAnswersAllowed() {
    if (this.currentEditableAnswers === 'answers1st') {
      const isEveryAnswerAllowed = this.answersForm.controls['answers1st'].value.every((answer: AnswerDetailsModel) => {
        return this.checkIsAnswerAllowed(answer, this.answerGroupId);
      });
      this.isAnswersDirty.emit(!isEveryAnswerAllowed);
    }

    if (this.currentEditableAnswers === 'answers') {
      const isEveryAnswerAllowed = this.answersForm.controls['answers'].value.every((answer: AnswerDetailsModel) => {
        return this.checkIsAnswerAllowed(answer, this.answerGroupId);
      });
      this.isAnswersDirty.emit(!isEveryAnswerAllowed);
    }

    if (this.currentEditableAnswers === 'answers2st') {
      const isEveryAnswerAllowed = this.answersForm.controls['answers2st'].value.every((answer: AnswerDetailsModel) => {
        return this.checkIsAnswerAllowed(answer, this.answerGroupId2);
      });
      this.isAnswersDirty2st.emit(!isEveryAnswerAllowed);
    }
  }


  checkIsAnswerAllowed(answer: AnswerDetailsModel, answerGroupId: number): boolean {
    const answerGroup1 = this.answerGroupsService.answerGroupList.find((group: any) => group.id === answerGroupId);
    const currentAnswers1 = answerGroup1?.answers || [];

    return currentAnswers1.some((item: any) => item.text === answer.text);
  }

  async updateAnswer( answer: AnswerDetailsModel, form?: FormGroup) {
    if (answer.externalData && answer.externalData.outcomeId === answer.id) {
      await this.createAnswer(answer, form, true);
      return;
    }

    await firstValueFrom(this.rounWizzardAnswerService.updateAnswer(
      {
        text: answer.text,
        imageUrl: answer.imageUrl,
        isHidden: false
      },
      answer.id
    ).pipe(
      catchError((error) => {
        this.snackBarService.showSnackBar(error.error.message, true);
        return throwError(error);
      })
    ));

    await firstValueFrom(this.rounWizzardAnswerService.getAnswersList(this.event, this.isExternalAnswersAvailable));
    await firstValueFrom(this.answerGroupsService.getAllAnswerGroups());
  }

  async deleteAnswer(answer) {
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${answer.text} answer`)
      .pipe(
        switchMap((response) => {
          if (response) {
            return this.rounWizzardAnswerService.deleteAnswersById(answer)
          } else {
            return of(null);
          }
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        })
      )
    );
    await firstValueFrom(this.rounWizzardAnswerService.getAnswersList(this.event, this.isExternalAnswersAvailable));
  }
}
