import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "@app/app.config";
import {
  CustomLeaderboardDetailsModel,
  RoundsForLeaderboardModel
} from "@models/CustomLeaderboardDetailsModel";
import { StreaksCustomLeaderboardModel, Tier } from "@models/StreaksCustomLeaderboardModel";

@Injectable({
  providedIn: 'root'
})
export class CustomLeaderboardService {

  isNeedUpdateLeaderboardsList$ = new BehaviorSubject<boolean>(true);

  customLeaderboardType$ = new BehaviorSubject(null);

  leaderboardsListSubject$ = new BehaviorSubject<CustomLeaderboardDetailsModel[] | StreaksCustomLeaderboardModel[]>([]);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getLeaderboardsList(requestBody =  {}): Observable<{records: CustomLeaderboardDetailsModel[] | StreaksCustomLeaderboardModel[], total: number }> {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param])
    })
    return this.http.get<{records: CustomLeaderboardDetailsModel[] | StreaksCustomLeaderboardModel[], total: number }>(this.uriConfig.leaderboards + '/list', { params });
  }

  createNewCustomLeaderboard(body) {
    return this.http.post(this.uriConfig.leaderboards, body);
  }

  updateCustomLeaderboard(leaderboard: Partial<CustomLeaderboardDetailsModel>, id: number) {
    return this.http.patch(this.uriConfig.leaderboards + '/' + id, leaderboard);
  }

  updateCustomLeaderboardDisplaying(leaderboardDisplaying: {isShown: boolean}, id: number) {
    return this.http.patch(this.uriConfig.leaderboards + '/' + id, leaderboardDisplaying);
  }

  downloadReportCustomLeaderboard(leaderboardId:  number) {
    return this.http.get(this.uriConfig.analytics + '/leaderboards/' + leaderboardId);
  }

  removeCustomLeaderboard(id: number, isSpecial?: boolean) {
    return this.http.delete(this.uriConfig.leaderboards + '/' + id + `${isSpecial ? '/special' : ''}`);
  }

  getRoundsList(leaderboardId: number): Observable<RoundsForLeaderboardModel[]> {
    return this.http.get<RoundsForLeaderboardModel[]>(this.uriConfig.rounds + '/for-leaderboard' + '/' + leaderboardId);
  }

  saveRounds(roundId: number, leaderboardId: number, selectedForLeaderboard: boolean): Observable<any> {
    return this.http.patch<any>(this.uriConfig.rounds + '/'+ roundId + '/leaderboard/' + leaderboardId, {selectedForLeaderboard: selectedForLeaderboard});
  }

  getRoundsListByDates(openDate: string, closeDate: string): Observable<RoundsForLeaderboardModel[]> {
    return this.http.get<RoundsForLeaderboardModel[]>(this.uriConfig.rounds + '/for-leaderboard' + '/' + openDate + '/' + closeDate);
  }

  editLeaderboardTiers(items: Partial<Tier[]>, tiersToDelete: number[], leaderboardId) {
    const body: {items?: Partial<Tier[]>, tierIdsToDelete?: number[]} = {};
    if (items && items.length) {
      body.items = items;
    }
    if (tiersToDelete && tiersToDelete.length) {
      body.tierIdsToDelete = tiersToDelete;
    }
    return this.http.patch(this.uriConfig.leaderboards +'/' + leaderboardId + '/tiers', body);
  }

  createSpecialCustomLeaderboardTiers(items: Partial<Tier[]>, id) {
    const body: {items?: Partial<Tier[]>} = {};
    if (items && items.length) {
      body.items = items;
    }
    return this.http.post(this.uriConfig.leaderboards + '/' + id + '/tiers', body);
  }

  createSpecialCustomLeaderboard(body) {
    return this.http.post(this.uriConfig.leaderboards + '/special', body);
  }

  updateSpecialCustomLeaderboard(leaderboard: Partial<CustomLeaderboardDetailsModel | StreaksCustomLeaderboardModel>, id: number) {
    return this.http.patch(this.uriConfig.leaderboards + '/' + id + '/special', leaderboard);
  }


  sendCustomLeaderboardReportToS3(leaderboardId: number) {
    return this.http.get(this.uriConfig.analytics + '/leaderboards/' + leaderboardId  + '/send');
  }

  downloadStreakCustomLeaderboardSubmissionReport(leaderboardId: number) {
    return this.http.get(this.uriConfig.analytics + '/leaderboards/' + leaderboardId + '/streak' );
  }

}
