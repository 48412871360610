import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  needUpdateContent$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getTextLabels(requestBody) {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param])
    })
    return this.http.get<{records: {[key:string] : string}[], total: number }>(this.uriConfig.cmsContent + '/labels', { params });
  }

  editTextLabel(body) {
    return this.http.patch(this.uriConfig.cmsContent + '/labels', body);
  }

  getLocalizedLable(lable, locale) {
    return lable[`Text value_${locale.i18n}`];
  }

  deleteLabel(id: string, body: any) {
    return this.http.request('delete', this.uriConfig.cms + '/labels/' + id, {
      body: body,
    });
  }
}
