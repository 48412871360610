import { Component, Input, OnInit } from '@angular/core';
import { QuestionDetailsModel } from "../../../common/models/QuestionDetailsModel";
import {
  CorrectAnswersDetailsFormInterface,
} from "../../../common/FormsModels/correct-answers-details-form-interface";
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { QuestionTypesEnum } from "../../../common/Enums/QuestionTypesEnum";
import { RoundHelperService } from "../../round-helper.service";
import { RoundStatusEnum } from "../../../common/Enums/RoundStatusEnum";
import { RoundProcessingService } from "../../../core/services/round-processing.service";

@Component({
  selector: 'correct-answers-prediction-section',
  templateUrl: './correct-answers-prediction-section.component.html',
  styleUrls: ['./correct-answers-prediction-section.component.scss']
})
export class CorrectAnswersPredictionSectionComponent implements OnInit {

  @Input() questions: QuestionDetailsModel[];

  @Input() voidTypeOptions = [];

  correctAnswersForm: FormGroup<CorrectAnswersDetailsFormInterface>;

  QuestionType = QuestionTypesEnum;

  RoundStatusEnum = RoundStatusEnum;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private roundProcessingService: RoundProcessingService,
    public roundHelperService: RoundHelperService,
  ) {}

  ngOnInit(): void {
    this.correctAnswersForm = this.rootFormGroup.control;
  }

  undoSubmittedResults() {
    this.roundProcessingService.undoSubmittedResults(this.roundHelperService.roundId);
  }
}
