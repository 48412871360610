import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UriConfig } from "../app.config";
import { BehaviorSubject, switchMap } from "rxjs";

@Injectable()
export class AnswerGroupsService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  _answerGroupList$ = new BehaviorSubject(undefined);

  answerGroupList$ = this._answerGroupList$.asObservable();

  existingAnswersId$ = new BehaviorSubject<number[]>([]);

  needUpdateAnswerGroup$ = new BehaviorSubject<boolean>(false);

  get answerGroupList() {
    return this._answerGroupList$.value;
  }

  getAllAnswerGroups() {
    return this._getAll().pipe(
      switchMap(response => {
        this._answerGroupList$.next(response['records']);
        return this._answerGroupList$.asObservable();
      })
    );
  }

  private _getAll() {
    return this.http.get(this.uriConfig.answerGroups);
  }

  createAnswerGroup(group) {
    return this.http.post(this.uriConfig.answerGroups, group);
  }

  updateAnswerGroup(group, groupId) {
    return this.http.patch(`${this.uriConfig.answerGroups}/${groupId}`, group);
  }

  deleteAnswerGroup(groupId) {
    return this.http.delete(`${this.uriConfig.answerGroups}/${groupId}`);
  }

}
