import {
  ChangeDetectionStrategy, Component,
  Inject, Injector, Input, OnDestroy, OnInit
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ValidationErrors
} from "@angular/forms";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { getErrorFromValidator } from "../../../modules/validators/validator-text.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";
import { MatSelectChange } from "@angular/material/select";

export interface SelectOption {
  value: string | number | null,
  label: string | number,
  disabled?: boolean
}

@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent
    }
  ]
})
export default class SelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  @Input() tooltipsText: string;

  @Input() tooltipPosition = TooltipPositionEnum.right;

  @Input() selectOptions: SelectOption[] = [];

  @Input() enableDeselect = false;

  @Input() multipleDisableEnabled = false;

  @Input() isSmall = false;

  multiple$ = new BehaviorSubject<boolean>(false);



  @Input()
  set multiple(value: boolean) {
    this.multiple$.next(value);
  }

  @Input()
  placeholder = '';

  @Input()
  set value(value) {
    if (value) {
      this.value$.next(value);
    }
  }

  @Input()
  set disabled(disabled) {
    this.disabled$.next(disabled);
  }

  errors$ = new BehaviorSubject([]);

  value$ = new BehaviorSubject(undefined);

  touched = false;

  disabled$ = new BehaviorSubject(false);

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  constructor (
    @Inject(Injector) private injector: Injector
  ) {}

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}
    if (this?.control?.touched) {
      this.validate();
    }

    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.validate();
      });

    if (this.enableDeselect) {
      this.selectOptions = [ {
        value: null,
        label: 'Clear'
      }, ...this.selectOptions]
    }
  }

  valueChanged(value: MatSelectChange) {
    this.onChange(value.value);
    this.validate();
  }

  writeValue(value: any): void {
    this.value$.next(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.validate();
    }
  }

  validate() {
    const errors = [];
    if (this?.control?.errors) {
      Object.keys(this.control.errors as ValidationErrors).forEach(key => {
        if(this.control.errors[key]) {
          errors.push(getErrorFromValidator(key, this.control.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
