import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";
import {DateFormats} from "../../Enums/date-formats";

@Pipe({
  name: 'datesFormatPipe'
})
export class DatesFormatPipePipe implements PipeTransform {

  transform(date: string | Date, monthFormat = 'long', convertToUTC = false): string {

    let utcDate = date;
    if (convertToUTC) {
      utcDate = moment.utc(utcDate, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }

    const formattedDate = moment(utcDate);


    const shortMonth = formattedDate.format("MMM");
    const shortYear = formattedDate.format("YY");
    const longYear = formattedDate.format("YYYY");
    const timeLocale  = formattedDate.format("HH:mm");
    const day = formattedDate.format('DD');
    switch (monthFormat) {

      case (DateFormats.MMM_DD_YYYY_at_hh_mmtz):
        return `${shortMonth} ${day}, ${longYear} at ${timeLocale}`;

      case (DateFormats.DD_MMMM_YYYY_hh_mmtz):
        return `${day} ${shortMonth} ${longYear}, ${timeLocale}`;

      default:
        return `${shortMonth}-${shortYear}`;
    }
  }
}
