<div class="load-container">
  <div class="dropdiv" [class.processing]="processing"
       *ngIf="showLoadIcon()">
    <input
      #nftInput
      (change)="onChange($event)"
      hidden="true"
      accept=".png, .jpg, .jpeg, .svg"
      type="file"
      onclick="this.value=null"/>
    <img src="../../../../../assets/images/icons/upload.svg" (click)="nftInput.click()">
    <div *ngIf="isLoading" class="disabled-overlay small-image-loader">
      <mat-spinner [diameter]="20" color="primary"></mat-spinner>
    </div>
  </div>
  <ng-container *ngIf="uploadedFiles?.imageUrl || uploadedFiles?.url">

    <div class="dropdiv with-image">
      <mat-icon (click)="deleteImage()" class="material-icons material-icons-outlined">clear</mat-icon>

    </div>
    <div class="image-uploaded-line">
          <image-with-loader
            [overlayHeight]="'35px'"
            [spinnerSize]="20"
            [imageSrc]="uploadedFiles.url || uploadedFiles.imageUrl" 
            class="cell-image full-height-cover">
          </image-with-loader>
    </div>
  </ng-container>
</div>


