<correct-card [predictionNumber]="predictionNumber"
              [isMain]="question ? question.isMain : false"
>
  <div card-header class="question-header-container">
    <div class="event-name">{{question.text}}</div>
    <status-chip *ngIf="difficultyLevel" [status]="difficultyLevel" [chipType]="LabelChipTypeEnum.STREAK_QUESTION">
      {{textService.createNameWithSpaceAndCapitalizeFirstLetter(difficultyLevel)}}
    </status-chip>
  </div>
  <div
    additional-text
    class="processed-result"
    *ngIf="question.correctAnswer || voidType">
    (Processed
    result: {{question.correctAnswer || textService.createNameWithSpaceAndCapitalizeFirstLetter(voidType)}})
  </div>
  <form form [formGroup]="singleQuestionForm">
    <custom-input
      [placeholder]="'Enter correct value'"
      [formControlName]="'value'">
    </custom-input>
    <mat-divider></mat-divider>
    <custom-radio-button
      formControlName="voidType"
      [enableDeselect]="true"
      [optionList]="voidOptions">
    </custom-radio-button>
  </form>
  <div warning *ngIf="!isMatched && isWarningNeed">No user has this entry</div>
</correct-card>
