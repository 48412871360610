import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { closeDateValidator } from 'src/app/common/modules/validators/close-date-validator';

interface EditCloseDateTimeModalData {
    closeDate: string,
}

@Component({
  selector: 'edit-close-datetime-modal',
  templateUrl: './edit-close-datetime-modal.component.html',
  styleUrls: ['./edit-close-datetime-modal.component.scss'],
})
export class EditCloseDateTimeModalComponent {

    public groupForm = new FormGroup({
        closeDate: new FormControl('', [Validators.required, closeDateValidator()]),
    });

  constructor(
    public dialogRef: MatDialogRef<EditCloseDateTimeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditCloseDateTimeModalData
  ) {
    this.groupForm.get('closeDate').patchValue(data.closeDate);
  }

  saveDate() {
    this.dialogRef.close(moment(this.groupForm.get('closeDate').value)?.format());
  }

}
