import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[trim]'
})
export class TrimDirective {
  value: string;

  @Input() ignoreTrim: boolean;

  constructor(
    public ref: ElementRef,
    public ngControl: NgControl) {
  }

  @HostListener('input', ['$event']) onInput($event) {
    const value = $event.target.value;
    if (this.ignoreTrim) {
      this.setUpTrimValue($event, value);
      return;
    }
    let updateValue = value;

    if(updateValue.startsWith(' ')) {
      updateValue = updateValue.replace(/^\s+/, '');
    }

    if (/\n\s/.test(updateValue)) {
      updateValue = value.replace(/\n\s/g, "\n");
    }

    this.setUpTrimValue($event, updateValue);
  }

  setUpTrimValue ($event, value) {
    $event.target.value = value;
    if (this.ngControl.name !== 'title' && this.ngControl.name) {
      this.ngControl.control.setValue(value);
    }

    this.value = value;
    $event.preventDefault();
  }
}
