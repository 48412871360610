import {
  ChangeDetectionStrategy, Component,
  EventEmitter,
  Inject, Injector, Input, OnInit, Output
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ValidationErrors
} from "@angular/forms";
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  lastValueFrom,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError,
  firstValueFrom
} from "rxjs";
import { getErrorFromValidator } from "../../../modules/validators/validator-text.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";
import {EventDetailsModel} from "../../../models/EventDetailsModel";
import { DateFormats } from "../../../Enums/date-formats";
import { EventsService } from "src/app/core/services/events.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { CreateEventModalComponent } from "../../_common-modal-components/create-event-modal/create-event-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../dialog/dialog.service";
import { APP_DATA } from "../../../../general.app.config";

@Component({
  selector: 'select-create-event',
  templateUrl: './select-create-event.component.html',
  styleUrls: ['./select-create-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectCreateEventComponent,
    }
  ]
})
export class SelectCreateEventComponent implements ControlValueAccessor, OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  @Input()
  tooltipsText: string;

  @Input()
  tooltipPosition = TooltipPositionEnum.right

  @Input()
  placeholder = '';

  @Input()
  type = '';

  @Output()
  onEdit = new EventEmitter<EventDetailsModel>();

  @Output()
  onDelete = new EventEmitter<EventDetailsModel>();

  @Output()
  onCreate = new EventEmitter<EventDetailsModel>();

  dateFormats = DateFormats;

  errors$ = new BehaviorSubject([]);

  eventName$ = new BehaviorSubject('');

  value$ = new BehaviorSubject('');

  touched = false;

  disabled$ = new BehaviorSubject(false);

  sportEvents$ = combineLatest([
    this.eventsService.eventList$,
    this.eventName$,
  ]).pipe(
    map(([sportEvents, eventName]) =>
      (sportEvents as EventDetailsModel[]).filter(event => event.name.includes(eventName))
    )
  )

  appData = APP_DATA;

  //eslint-disable-next-line
  onChange = (value) => {};

  onTouched = () => {};

  control: AbstractControl;

  constructor (
    @Inject(Injector) private injector: Injector,
    private eventsService: EventsService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    try {
      const injectedControl = this.injector.get(NgControl);

      switch (injectedControl.constructor) {
        case NgModel: {
          const { control } = injectedControl as NgModel;
          this.control = control;
          break;
        }
        case FormControlName: {
          this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
          break;
        }
        default: {
          this.control = (injectedControl as FormControlDirective).form as FormControl;
          break;
        }
      }
      //eslint-disable-next-line
    } catch (error) {}

    combineLatest([
      this.value$,
      this.eventsService.eventList$
    ]).pipe(takeUntil(this.unsubscribe$))
    .subscribe(([value, sportEvents]) => {
      const currentEvent = (sportEvents as EventDetailsModel[]).find(item => item.id === Number(value));
      if (currentEvent) {
        this.eventName$.next(currentEvent.name)
      }
    });

    this.control.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.validate());
  }

  getEventName(option) {
    return option?.name;
  }

  inputEventName($event) {
    this.eventName$.next($event.target.value)
  }

  validate() {
    const errors = [];
    if (this.control?.errors) {
      Object.keys(this.control.errors as ValidationErrors).forEach(key => {
        if(this.control?.errors?.[key]) {
          errors.push(getErrorFromValidator(key, this.control.errors[key]));
        }
      });
    }
    this.errors$.next(errors);
  }

  writeValue(value: string): void {
    this.value$.next(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.validate();
    }
  }

  setEvent(event) {
    this.onChange(event.option.value);
    this.value$.next(event.option.value)
    this.validate();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  async editEvent($event, sportEvent: EventDetailsModel) {
    $event.stopPropagation();
    const ref = this.dialog
      .open(CreateEventModalComponent, {
        panelClass: ['event-builder'],
        data: sportEvent,
        autoFocus: false
      });
    await lastValueFrom(ref.afterClosed());
    await firstValueFrom(this.eventsService.loadEvent());
  }
  async deleteEvent(event, sportEvent: EventDetailsModel) {
    event.stopPropagation();
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${sportEvent.name} event`)
      .pipe(
        switchMap((response) => {
          if (response) {
            return this.eventsService.deleteEventById(sportEvent.id)
              .pipe(
                tap(() => this.eventsService.needUpdateEventList()))
          } else {
            return of(null);
          }
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        })
      )
    );
    await firstValueFrom(this.eventsService.loadEvent());
  }

  async createEvent($event) {
    $event.stopPropagation();
    const ref = this.dialog
      .open(CreateEventModalComponent, {
        panelClass: ['event-builder'],
        data: undefined,
        autoFocus: false
      });
    await lastValueFrom(ref.afterClosed());
    await firstValueFrom(this.eventsService.loadEvent());
  }

}
