
<div class="form-field-with-label no-error-field">
  <div #ref>
    <mat-label>
      <ng-content></ng-content>
      <i
        *ngIf="tooltipsText"
        class="fa-solid fa-circle-info"
        [matTooltipPosition]="tooltipPosition"
        #tooltip="matTooltip"
        [matTooltip]="tooltipsText"
        [matTooltipClass]="tooltipPosition"></i>
    </mat-label>
  </div>
  <span *ngIf=" ! ref.children.length"></span>

  <mat-radio-group
    color="primary"
    [value]="value$ | async"
    [ngClass]="{'vertical': isVertical}"
    [disabled]="disabled$ | async"
    [formControl]="control"
    (change)="valueChanged($event)">
    <mat-radio-button
      *ngFor="let type of optionList"
      (click)="onClick($event)"
      [value]="type.value">
      {{type.label}}
    </mat-radio-button>
  </mat-radio-group>
</div>
