import {
  CorrectAnswerPredictionServiceService
} from "@app/new-round-wizzard/wizzard-sections/correct-answers-prediction-section/correct-answer-prediction-service.service";
import { Injectable } from "@angular/core";
import { QuestionTypesEnum } from "@enums/QuestionTypesEnum";
import { CorrectAnswerItemDetailsModel } from "@models/CorreectAnswerDetailsModel";
import { AnswerService } from "./answer.service";
import { SnackBarService } from "./snack-bar.service";
import { RoundService } from "./round.service";
import { DialogService } from "@components/_base-component/dialog/dialog.service";
import { EventDetailsModel } from "@models/EventDetailsModel";
import { RoundClassEnum } from "@enums/RoundClassEnum";
import { CreateRoundDetailsModel, Round } from "@models/CreateRoundDetailsModel";
import { AbstractControl, FormArray, FormControl } from "@angular/forms";
import {
  PredictionPrizeSectionService
} from "@app/new-round-wizzard/wizzard-sections/prediction-prize-section/prize-section.service";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  finalize,
  firstValueFrom, forkJoin,
  from,
  fromEvent,
  interval,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  throwError
} from "rxjs";
import { ProfileService } from "./profile.service";
import { LocalStorageService } from "./local-storage.service";
import { RoundStatusEnum } from "src/app/common/Enums/RoundStatusEnum";
import { RoundSportEnum } from "../../common/Enums/RoundSportEnum";
import { RoundHelperService } from "../../new-round-wizzard/round-helper.service";
import { EventsService } from "./events.service";
import { AuthService } from "./auth.service";
import { LocalizationService } from "./localization.service";
import { PlayerItemDetailsModel } from "../../common/models/PlayerItemDetailsModel";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CorrectAnswerHelperService } from "./streak/correct-answer-helper.service";
import { CmsService } from "./cms.service";

@Injectable()
export class RoundProcessingService {

  isLoading$ = new BehaviorSubject<boolean>(false);

  isDraft$ = new BehaviorSubject<boolean>(false);

  questionFormStateChanged$ = new Subject<boolean>();

  logoutEnabled$ = new BehaviorSubject<boolean>(false);

  uniqueKey: string;

  locales$: Observable<{ i18n: string, id: number }[]>;

  isExternalAnswersAvailable$ = new BehaviorSubject<boolean>(true);
  private  newWindow;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private predictionPrizeSectionService: PredictionPrizeSectionService,
    private profileService: ProfileService,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private correctAnswerPredictionServiceService: CorrectAnswerPredictionServiceService,
    private answerService: AnswerService,
    private roundHelperService: RoundHelperService,
    private eventsService: EventsService,
    private authService: AuthService,
    private localizationService: LocalizationService,
    private roundService: RoundService,
    private correctAnswerHelperService: CorrectAnswerHelperService,
    private cmsService: CmsService
  ) {
    this.locales$ = this.localizationService.getVendorLocales().pipe(
      map(locales => locales.map(({i18n, id}) => ({i18n, id})))
    );
  }

  async saveDraft(roundDetailsForm, questionForm, roundId, dateUpdate?: boolean, bonusUpdate?: boolean) {
    const checkValidity = !(dateUpdate || bonusUpdate) ? !this.checkRoundValidity(roundDetailsForm, questionForm) : !roundDetailsForm.value.closeDate;
    if (checkValidity) {
      (questionForm.get('questions') as FormArray).controls.forEach(control => control.markAsDirty());
      roundDetailsForm.markAsDirty();
      this.questionFormStateChanged$.next(true);
      return;
    }

    if (
      this.roundHelperService.roundStatus === RoundStatusEnum.CLOSED && !(dateUpdate || bonusUpdate)
    ) {
      return;
    }
    const prizeType = await firstValueFrom(this.predictionPrizeSectionService.roundPrizeType$);
    const roundBody = this.getRoundBody(roundDetailsForm, questionForm, prizeType);
    if(bonusUpdate) {
      delete  roundBody.round.closeDate
    }
    const request$ = roundId ?
      this.roundService.editeDraft(roundBody, roundId) :
      this.roundService.createDraft(roundBody);
    await this.saveRoundDraft(request$);
    this.roundHelperService.roundTryToSave = false;
    if (!(dateUpdate || bonusUpdate)) {
      this.closeModal();
    }
  }

  async previewAndPublish(roundDetailsForm: AbstractControl, questionForm: AbstractControl, roundId) {
    if (!this.checkRoundValidity(roundDetailsForm, questionForm)) {
      (questionForm.get('questions') as FormArray).controls.forEach(control => control.markAsDirty());
      roundDetailsForm.markAsDirty();
      this.questionFormStateChanged$.next(true);
      return;
    }

    const questionsId = questionForm.get('questions').getRawValue()
        .map(question => question.id)
        .filter(value => value);
    const prizeType = await firstValueFrom(this.predictionPrizeSectionService.roundPrizeType$);

    const roundBody = this.getRoundBody(roundDetailsForm, questionForm, prizeType);

    roundBody.round.fromEdit = !!roundId;
    await this.previewAndPublishRound(roundBody, roundId, questionsId);

    const event = roundDetailsForm.get('sportEvent').value;

    this.roundHelperService.roundTryToSave = false;

    if(event?.externalId && this.externalAnswersSelected(questionForm.get('questions').value)
      || event?.externalId && this.isDraft$.value) {
      this.translateAndWriteCmsRoundName(event);
    }

  }

  externalAnswersSelected(questions) {
    return questions.some(item =>
      item?.additional?.answers.every(answer => answer?.externalOutcomeId !== null)
    );
  }

  translateAndWriteCmsRoundName(event: { name: string; }) {
    this.locales$.pipe(
      map(locales => locales.map(res => ({q: [event.name], target: res.i18n}))),
      switchMap(body => forkJoin([
        this.localizationService.getGoogleTranslation({items: body}),
        this.cmsService.getTextLabels({name: 'iframe-v3-upsell-banner-header-right-text'})
      ])),
      switchMap(([translationResponse, textLabelsResponse]) => {
        const data = translationResponse.data;
        const records = textLabelsResponse.records;
        const updatedDataArray = this.localizationService.mapTranslationsToCmsContentBody(event.name, records, data);

        const editObservables = updatedDataArray.map(res => this.cmsService.editTextLabel(res));

        return forkJoin(editObservables).pipe(
          catchError(error => {
            console.error('An error occurred while editing text labels:', error);
            return of(null);
          })
        );
      })
    ).subscribe();
  }

  isDownloadingAnswersAvailable(eventId?) {
    if (!this.roundHelperService.curentRound) {
      return of(true);
    }
    return this.eventsService.eventList$.pipe(
      map(eventList => {
        const currentEventId = this.roundHelperService.curentRound.class === RoundClassEnum.Single ? this.roundHelperService.curentRound.sportEvent.id : eventId;
				return !!eventList.find(event => event.id === currentEventId);
      })
    )
  }


  getMessageObservable(): Observable<any> {
    return fromEvent<MessageEvent>(window, 'message').pipe(
      switchMap(event => {
        if (event.data === 'childWindowClosed' && this.logoutEnabled$.value) {
          this.logoutEnabled$.next(false);
          return this.authService.logOutB2C();
        } else {
          return of(event);
        }
      }),
    );
  }

  openIframeWindow(player: PlayerItemDetailsModel) {
     combineLatest([
       this.getAccessAndRefreshTokensForB2C(player.id),
       this.profileService.currentUser$,
     ]).pipe(
      take(1),
      switchMap(([{accessToken, refreshToken}, {domain}]) => {
        //need for testing localy
        const fullSiteAddress = environment.isLocal ? 'http://localhost:4200' : 'https://' + domain;

        const url = `${fullSiteAddress}/?userToken=${accessToken}`;
        window.open(url, '_blank');
        this.logoutEnabled$.next(true);
        this.localStorageService.setAccessTokenPreview(accessToken);
        this.localStorageService.setRefreshTokenPreview(refreshToken);
        return this.getMessageObservable();
      }),
       catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message ?? error.error, true);
          return throwError(error);
        }),
      ).subscribe();
  }

  async saveRoundDraft(request$) {
    this.isLoading$.next(true);

    await lastValueFrom(
      request$.pipe(
        tap(() => {
          this.roundService.needUpdateRoundList();
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        }),
        finalize(() => this.isLoading$.next(false))
      )
    )
  }

  closeModal() {
    this.dialogService.hardCloseModal();
  }


  async previewAndPublishRound(roundBody, roundId, questionsId) {
    const {accessToken} = await firstValueFrom(this.getUserToken());
    const locales = await firstValueFrom(this.localizationService.vendorLocales$);
    await lastValueFrom(
      this.roundService.createRoundPreview(roundBody)
        .pipe(
          tap(response => {
            this.uniqueKey = response.key;

            const {domain} = this.profileService.currentUser$.value;
            //need for testing localy
            const fullSiteAddress = environment.isLocal ? 'http://localhost:4200' : 'https://' + domain;

            const token = this.localStorageService.getToken();

            //TODO return when preview will work
            // let url = `${fullSiteAddress}/frame/landing/?accessToken=${token}&uniqueKey=${response.key}&userToken=${accessToken}`;
            let url = `${fullSiteAddress}/preview/?accessToken=${token}&uniqueKey=${response.key}&userToken=${accessToken}`;
            if (roundId) {
              url += `&roundId=${roundId}`;
            }
            if (roundId && questionsId && questionsId.length) {
              const roundQuestion = questionsId.map(id => `questionId=${id}`).join('&')
              url += `&${roundQuestion}`;
            }
            if (roundId && locales && locales.length) {
              const vendorLocales = locales.map(locale => `previewLocales=${encodeURIComponent(locale.i18n)}`).join('&');
              url += `&${vendorLocales}`
            }

            const windowName = 'previewWindow'

              if(!this.newWindow || this.newWindow.closed) {
                this.newWindow  = window.open(url, '_blank', `width=500,height=1000,location=no,toolbar=no,name=${windowName}`);
              } else {
                this.newWindow.focus()
              }


            // it is needed for unsubscribe on "message" of window when new Window is closed
            if(this.newWindow)
              this.unsubscripeIfClose(this.newWindow);

            window.addEventListener("message", (event) =>
              this.processModalEvent(event, fullSiteAddress, this.newWindow, roundId, roundBody, response.key)
            );
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          }),
        )
    )
  }

   // it is needed for unsubscribe on "message" of window when new Window is closed
   private async unsubscripeIfClose(newWindow: Window) {
    await firstValueFrom(
      interval(100).pipe(
        filter(() => newWindow.closed),
        tap(() => window.removeAllListeners('message'))
      )
    )
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async processModalEvent(event, fullSiteAddress, newWindow, roundId, roundBody, key) {
    if (event.origin !== fullSiteAddress) {
      return;
    }
    if (event.data === "roundPublish") {
      if (key !== this.uniqueKey) return;
      if (roundId && this.roundHelperService.roundStatus === RoundStatusEnum.OPENED) {
        delete roundBody.round.openDate;
      }
      roundBody.publish = true;
      await lastValueFrom(
        (
          roundId ?
            this.roundService.editeDraft(roundBody, roundId) :
            this.roundService.createRoundFromPreview(roundBody, key)
        ).pipe(
          switchMap((data) => {
            return this.localizationService.vendorLocales$.pipe(
              map(locales => {
                const isLocalizedPopupDisplayed = roundId ? (data.isRoundNonLocalized && this.isDraft$.value && locales.length) : locales.length;
                if (isLocalizedPopupDisplayed) {
                  this.dialogService.openLocalizedErrorMessage({id: roundId ?? data.id }).then(() => {
                    this.roundService.needUpdateRoundList();
                  });
                } else {
                  this.roundService.needUpdateRoundList();
                  this.dialogService.hardCloseModal();
                }
                return of(data)
              })
            )
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
      );
      newWindow.close();
    }
  }


  getRoundBody(roundDetailsForm, questionForm, prizeType) {
    const {
      name,
      description,
      closeDate,
      openDate,
      sport,
      backgroundImageDesktopUrl,
      backgroundImageMobileUrl,
      displayPrizeSum,
      imageUrl,
      visibleForUsers,
      ticketRequired,
      bonus
    } = roundDetailsForm.getRawValue();

    const formattedBonus = bonus === '' ? null : bonus;

    const sportArray = this.getRoundSport(sport);
    const sportEvent = roundDetailsForm.get('sportEvent')?.value as EventDetailsModel;
    const roundClass = sportEvent ? RoundClassEnum.Single : RoundClassEnum.Multi;
    const type = roundDetailsForm.get('type').value;


    const round: Round = {
      name,
      closeDate: typeof closeDate === 'string' ? closeDate : closeDate.toISOString(),
      sport: sportArray,
      type,
      class: roundClass,
      prizeType,
      displayPrizeSum,
			imageUrl,
      visibleForUsers,
      ticketRequired,
      bonus: formattedBonus
    }

    round.description = description || null;

    if (openDate) {
      round.openDate = typeof openDate === 'string' ? openDate : openDate.toISOString()
    } else {
      const roundOpenDate = roundDetailsForm.get('openDate').value;
      round.openDate = typeof roundOpenDate === 'string' ? roundOpenDate : roundOpenDate.toISOString();
    }

    round.backgroundImageDesktopUrl = backgroundImageDesktopUrl;
    round.backgroundImageMobileUrl = backgroundImageMobileUrl;

    if (sportEvent) {
      round.sportEventId = sportEvent.id
    }

    const questionControlArray = questionForm.get('questions') as FormArray;

    const questionsIds = questionControlArray.controls
      .map(control => control.get('id').value);

    const roundDetails: CreateRoundDetailsModel = {round, questionsIds};

    const prizes = this.predictionPrizeSectionService.createPrizeObject(prizeType);

    if (prizes && this.predictionPrizeSectionService.roundPrizeForm.touched) {
      roundDetails.round.prize = prizes;
    }
    return roundDetails;
  }

  checkRoundValidity(roundDetailsForm, questionForm) {
    return this.checkIsPredictionRoundValid(roundDetailsForm, questionForm);
  }


  async submitCorrectAnswers(roundId) {
    this.isLoading$.next(true);
    const prizeType = await firstValueFrom(this.predictionPrizeSectionService.roundPrizeType$);
    const requestArray = [];
    if (this.predictionPrizeSectionService.prizes.dirty && this.predictionPrizeSectionService.prizes.touched) {
      const prize = this.predictionPrizeSectionService.createPrizeObject(prizeType);
      const roundBody = {
        round: {
          prizeType,
          prize
        }
      }
      requestArray.push(this.roundService.updateRoundById(roundId, roundBody));
    }

    if (this.correctAnswerPredictionServiceService.correctEventForm?.dirty) {
      const {awayTeam, homeTeam, id} = this.correctAnswerPredictionServiceService.correctEventForm.value;
      requestArray.push(this.eventsService.updateEvent(id, {score: `${homeTeam}:${awayTeam}`}))
    }

    if (this.correctAnswerHelperService.correctAnswersForm.dirty) {
      const answersArray = this.prepareCorrectAnswersArray();
      const correctAnswers = answersArray.filter(item => item.value || item.voidType || item.answerId);
      const answersToReset = this.getQuestionToBeReset()
      if (answersToReset && answersToReset.length) {
        requestArray.push(this.answerService.undoSubmittedResults(roundId, answersToReset));
      }
      requestArray.push(this.answerService.bulkSubmitCorrectAnswers({roundId, correctAnswers}));
    }
    await lastValueFrom(
      combineLatest(requestArray).pipe(
        tap(() => {
          this.roundService.needUpdateRoundList();
          this.snackBarService.showSnackBar('Answers submitted');
          this.dialogService.hardCloseModal();
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error)
        }),
        finalize(() => this.isLoading$.next(false))
      )
    );
  }

  undoSubmittedResults (roundId) {
    this.isLoading$.next(true);
      this.answerService.undoSubmittedResults(roundId).pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.roundService.needUpdateRoundList();
          this.snackBarService.showSnackBar('Answers reset');
          this.dialogService.hardCloseModal();
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error)
        }),
        finalize(() => this.isLoading$.next(false))
      ).subscribe()
  }

  getQuestionToBeReset() {
    const resetQuestionArray = [];
    this.correctAnswerHelperService.questionsForm.controls
      .map(control => {
        const voidTypeControl = control.get('voidType') as FormControl;
        const {awayTeam, homeTeam, questionId, questionType, voidType, value, answerId} = control.value;
        if (!voidType && voidTypeControl.touched) {
          resetQuestionArray.push(questionId);
        } else {
          switch (questionType) {
            case QuestionTypesEnum.SCORE:
            case QuestionTypesEnum.SCORE_PLUS: {
              const defaultAwayTeamValue = (control.get('awayTeam') as FormControl).defaultValue;
              const defaultHomeTeamValue = (control.get('homeTeam') as FormControl).defaultValue;
              if (!awayTeam && !homeTeam && awayTeam !== defaultAwayTeamValue && homeTeam !== defaultHomeTeamValue) {
                resetQuestionArray.push(questionId);
              }
              break;
            }
            case QuestionTypesEnum.RANGE: {
              const defaultValue = (control.get('value') as FormControl).defaultValue;
              if (!value && value !== defaultValue) {
                resetQuestionArray.push(questionId);
              }
              break;
            }
            default: {
              const defaultAnswer = (control.get('answerId') as FormControl).defaultValue;
              if (!answerId && answerId !== defaultAnswer) {
                resetQuestionArray.push(questionId);
              }
            }
          }
        }

      })
    return resetQuestionArray;
  }

  prepareCorrectAnswersArray() {
    return this.correctAnswerHelperService.questionsForm.controls
      .map((control => {
        const {awayTeam, homeTeam, questionId, questionType, voidType, value, answerId} = control.value;
        const correctAnswer: CorrectAnswerItemDetailsModel = {questionId};

        if (value || (awayTeam && homeTeam) || voidType || (answerId && answerId !== -1)) {
          const score = `${homeTeam}:${awayTeam}`;
          if (voidType) {
            correctAnswer.voidType = voidType;
          } else {
            switch (questionType) {
              case QuestionTypesEnum.SCORE:
              case QuestionTypesEnum.SCORE_PLUS:
                correctAnswer.value = score;
                break;
              case QuestionTypesEnum.RANGE:
                correctAnswer.value = value ? value.toString() : null;
                break;
              default:
                correctAnswer.answerId = answerId;
            }
          }
          return correctAnswer
        } else {
            correctAnswer.value = null;
          }
      return correctAnswer;
  }));
  }

  getRoundSport(sport: string | string[] | null) {
    if (!sport) return [];
    if (typeof sport === 'string') return [RoundSportEnum[sport]];
    return sport.map(type => RoundSportEnum[type]);
  }

  checkIsPredictionRoundValid(roundDetailsForm, questionForm) {
    const questionControlArray = questionForm.get('questions') as FormArray;

    const isAllQuestionSaved = questionControlArray.controls.every(control => {
      return !!control.get('id').value
    })
    if (!roundDetailsForm.valid) {
      const formNames = ['name', 'description', 'openDate', 'closeDate', 'sport']
      formNames.forEach(name => {
        if (!roundDetailsForm.get(name).valid) {
          roundDetailsForm.get(name).markAsTouched()
          roundDetailsForm.get(name).updateValueAndValidity()
        }
      })
    }
    if (!isAllQuestionSaved) {
      this.roundHelperService.roundTryToSave = true;
    }
    return roundDetailsForm.valid && isAllQuestionSaved;
  }

  checkIsStreakRoundValid() {
    return true;
  }

  getUserToken() {
    return this.authService.getUserToken();
  }

  getAccessAndRefreshTokensForB2C(userId: number) {
    return this.authService.getAccessAndRefreshTokensForB2C(userId);
  }

  submitStreakCorrectAnswers(roundId) {
    if (this.correctAnswerHelperService.correctAnswersForm.dirty) {
      const answersArray = this.prepareCorrectAnswersArray();
      const correctAnswers = answersArray.filter(item => item.value || item.answerId || item.voidType);
      return this.answerService.bulkSubmitCorrectAnswers({roundId, correctAnswers, isStreak: true})
    }
  }
}
