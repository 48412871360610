import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {JwtTokenInterceptor} from "./interseptors/jwt-token.interceptor";
import {SnackBarService} from "./services/snack-bar.service";
import {MaterialModule} from "../material/material.module";



@NgModule({
  declarations: [
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    SnackBarService
  ],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class CoreModule { }
