import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {APP_ROUTES} from "./app.routes.config";
import {AuthGuard} from "./core/guards/auth.guard";
import {AuthAdminGuard} from "./core/guards/auth-admin.guard";
import {SessionGuard} from "./core/guards/session.guard";

const routes: Routes = [
  {
    path: APP_ROUTES.AUTH,
    canActivate: [SessionGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.RESTORE_PASSWORD,
    canActivate: [SessionGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.PORTAL,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main-content-area-component/main-content-area.module').then((m) => m.MainContentAreaModule),
  },
  {
    path: APP_ROUTES.PORTAL_ADMIN,
    canActivate: [AuthAdminGuard],
    loadChildren: () => import('./main-content-area-component/main-content-area.module').then((m) => m.MainContentAreaModule),
  },
  {
    path: '',
    redirectTo: APP_ROUTES.PORTAL,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', // or 'top'
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64], // [x, y] - adjust scroll offset
    onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
