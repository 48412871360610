
<div class="form-field-with-label">
  <ng-container *ngFor="let answerConfig of answerConfigList$ | async">
    <div class="item">
      <mat-label>
        {{answerConfig.title}}
      </mat-label>
      <ng-container *ngFor="let control of answerConfig.controlList; let last = last; let index = index">
        <question-autocomplete
          *ngIf="answerList$ | async as answerList"
          [displayAdd]="last && (answerConfig.controlList?.length < (maxAnswerLength$ | async))"
          [answerList]="getFilteredAnswerList(answerList, control)"
          [canRemove]="answerConfig.controlList?.length > 1"
          [form]="control"
          [hideImage]="hideImage"
          [isFirst]="!index"
          [isLast]="index + 1 === answerConfig.controlList.length"
          [fromModalWindow]="fromModalWindow"
          (changeOrder)="changeOrderEvent($event, index, answerConfig.controlName)"
          (onSetAnswer)="setAnswer($event, control)"
          (onDeleteAnswer)="deleteAnswer($event)"
          (onCreateAnswer)="createAnswer($event, control)"
          (onUpdateAnswer)="updateAnswer($event, control)"
          (onRemoveForm)="remove(index, answerConfig.controlName)"
          (onTouchedForm)="touched()"
          (onAddForm)="addByType(answerConfig.controlName)">
        </question-autocomplete>
      </ng-container>
        <mat-error *ngFor="let error of answerConfig.errors">
          <i class="fas fa-exclamation-circle"></i>
          <span>{{error}}</span>
        </mat-error>
    </div>
  </ng-container>
  <div class="error" *ngIf="fromModalWindow && (answerGroupsService.existingAnswersId$ | async).length as answerGroupsServiceLength">
    {{APP_DATA.EXIST_ANSWER.replace('{answer_count}', answerGroupsServiceLength > 1 ? 'answers' : 'answer')}}
  </div>
</div>
