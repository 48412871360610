import moment from "moment/moment";

export const openDateValidator = (compareDateOnly) => (control) => {
  let dateNow = Date.now();
  let openDate = control.value;
  if (compareDateOnly && openDate) {
    dateNow = moment(dateNow).startOf('day').valueOf();
    openDate = moment(openDate).startOf('day');
  }
  if (openDate && moment(openDate).isBefore(dateNow)) {
    return {
      invalidOpenDate: {value: control.value}
    }
  }
  if (control.errors && ('incorrect' in control.errors || ('invalidOpenDate' in control.errors && openDate && moment(openDate).isBefore(dateNow)))) {
    return control.errors;
  }
  return null;
};
