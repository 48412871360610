import {RoundTypeItem} from "./RoundTypeItem";
import {RoundTypesEnum} from "../Enums/RoundTypesEnum";

export const roundTypes : RoundTypeItem[] = [
  {
    beVariable: RoundTypesEnum.Sport,
    generalSportTypeLabel: 'Prediction',
    oneRoundSportTypeLabel: 'Sport'
  },
  {
    beVariable: RoundTypesEnum.Other,
    generalSportTypeLabel: 'Prediction',
    oneRoundSportTypeLabel: 'Other'
  }
]

