<img
  class="item-image"
  (load)="onImageLoaded()"
  [ngClass]="getClassName()"
  [src]="imageSrc">
<div
  *ngIf="!isImageLoading"
  class="disabled-overlay image"
  [ngClass]="getOverlayClassName()"
  [style.height]="overlayHeight"
  [style.width]="fixedOverleyWidth"
>
  <mat-spinner [diameter]="spinnerSize" color="primary"></mat-spinner>
</div>
