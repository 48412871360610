import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from "rxjs";
import { CreateAnswerGroupModalComponent } from './create-answer-group-modal.component';
import { AnswerGroupsService } from "../answer-groups.service";

@Injectable()
export class CreateAnswerGroupModalService {

  constructor(
    private dialog: MatDialog,
    private answerGroupsService: AnswerGroupsService,
  ) {}

  async open(data: {
    group?: any,
    questionType: any,
    answers?: any,
    event?: null | number,
    disableExternalEvent?: boolean
  }) {
    const ref = this.dialog
      .open(CreateAnswerGroupModalComponent, {
        panelClass: ['event-builder'],
        data: {
          questionType: data.questionType,
          group: data.group,
          answers: data.answers,
          event: data.event,
          disableExternalEvent: data.disableExternalEvent
        },
        autoFocus: false
      });
    const response = await firstValueFrom(ref.beforeClosed());
    this.answerGroupsService.existingAnswersId$.next([]);
    return response;
  }

}
