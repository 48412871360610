
<div
  class="form-field-with-label"
  [ngClass]="{valid: (control.touched && control.valid || control.disabled && control.value)}">
  <mat-label>
    <ng-content></ng-content>
    <i
      *ngIf="tooltipsText"
      class="fa-solid fa-circle-info"
      [matTooltipPosition]="tooltipPosition"
      #tooltip="matTooltip"
      [matTooltip]="tooltipsText"
      [matTooltipClass]="tooltipPosition"></i>
  </mat-label>
  <mat-form-field class="date-time" appearance="outline" [ngClass]="{'time-picker': pickerType === 'timer'} ">
    <input
      matInput
      readonly
      [owlDateTime]="openDatePicker"
      [placeholder]="placeholder"
      [attr.disabled]="disabled$ | async"
      [formControl]="control"
      (focusout)="markAsTouched()"
      (ngModelChange)="valueChanged($event)">
    <mat-icon
            (click)="clearValue.emit()"
            *ngIf="control.value && isAbleToClear"
            matSuffix
            class="material-icons clear material-icons-outlined">
      clear
    </mat-icon>
    <mat-icon
            *ngIf="pickerType === 'timer'"
            matSuffix
            class="material-icons timer material-icons-outlined">
      schedule
    </mat-icon>
    <mat-datepicker-toggle
      matSuffix
      [owlDateTimeTrigger]="openDatePicker">
    </mat-datepicker-toggle>
    <owl-date-time [hour12Timer]="false" [pickerType]="pickerType" (afterPickerClosed)="markAsTouched()" #openDatePicker>
    </owl-date-time>
  </mat-form-field>
  <mat-error *ngFor="let error of errors$  | async">
    <i class="fas fa-exclamation-circle"></i>
    <span>{{error}}</span>
  </mat-error>
</div>
