<div #mainImage class="form-field-with-label">
    <div class="flex-container">
      <div class="full-flex-item">
        <mat-form-field  appearance="fill" class="autocomplete-selector-item">
        <input
          [ngClass]="{'exist-answers': isAnswerExist(answerGroupsService.existingAnswersId$ | async, answer$ | async)}"
          matInput
          placeholder="Select answer"
          aria-label="State"
          [value]="answerText$ | async"
          (keypress)="keyPress($event)"
          (focus)="touched()"
          (input)="input($event)"
          [matAutocomplete]="auto">
        <mat-icon
          class="done-icon action-icon"
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          *ngIf="(answerText$ | async) && (answerText$ | async) !== (answer$ | async)?.text"
          (click)="create($event)"
          matSuffix>
          done
        </mat-icon>
        <mat-icon
          class="remove-icon action-icon"
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          *ngIf="!isFirst && !fromModalWindow"
          (click)="changeOrderEvent($event, MoveDirectionEnum.UP)"
          matSuffix>
          arrow_upward
        </mat-icon>
        <mat-icon
          class="remove-icon action-icon"
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          (click)="changeOrderEvent($event, MoveDirectionEnum.DOWN)"
          matSuffix
          *ngIf="!isLast && !fromModalWindow"
        >
          arrow_downward
        </mat-icon>
        <mat-icon
          class="add-icon action-icon"
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          matSuffix
          *ngIf="(displayAdd$ | async)"
          (click)="add($event)">
          add_circle_outline
        </mat-icon>
        <mat-icon
          class="remove-icon action-icon"
          matRipple
          [matRippleRadius]="15"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          matSuffix
          (click)="removeAnswerForm()"
          *ngIf="(canRemove$ | async)">
          clear
        </mat-icon>
        <mat-autocomplete
          (optionSelected)="setAnswer($event?.option?.value)"
          scrollControl
          #auto="matAutocomplete">
          <mat-option
            class="answer-option"
            *ngFor="let answer of answerList$ | async; let answerIndex = index; trackBy:trackByAnswerList"
            [value]="answer"
            [id]="answer.id">
            <div *ngIf="(editId$ | async) === answer.id; else regularItem" class="edit-input">
              <div
                class="div-with-error"
                (click)="prevent($event)"
                [ngClass]="{'invalid': !text?.value?.length || text?.value?.length > maxAnswerTextLength}">
                <div class="answer-input-container">
                  <input
                    (keydown)="processEditKeydown($event)"
                    [value]="answer.text"
                    #text>
                  </div>
                  <div #image class="edit-answer-container" *ngIf="!hideImage">
                    <small-file-loader
                      (imageErrorMassage)="image.error = $event"
                      [image]="answer"
                      (click)="prevent($event)"
                      (fileUpload)="addImageToEdit($event)">
                    </small-file-loader>
                    <mat-icon
                      matRipple
                      [matRippleRadius]="10"
                      [matRippleUnbounded]="true"
                      [matRippleCentered]="true"
                      matSuffix
                      class="save-answer-icon"
                      *ngIf="text?.value && text?.value?.length < maxAnswerTextLength && !image?.error"
                      (click)="updateAnswer($event, text?.value, answer)">
                      done
                    </mat-icon>
                    <mat-error *ngIf="image?.error">
                      <i class="fas fa-exclamation-circle"></i>
                      <span>{{appData.BIG_IMAGE}}</span>
                    </mat-error>
                  </div>

                </div>
                <mat-error *ngIf="!text?.value?.length">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MANDATORY}}</span>
                </mat-error>
                <mat-error *ngIf="text?.value?.length > maxAnswerTextLength">
                  <i class="fas fa-exclamation-circle"></i>
                  <span>{{appData.MAX_LENGHT_128}}</span>
                </mat-error>

              </div>
              <ng-template #regularItem>
                <div class="mat-option-item">
                  <ng-container>
                    <div class="image-loader-container" *ngIf="!hideImage">
                      <image-with-loader
                        *ngIf="answer.imageUrl; else emptySpace"
                        [overlayHeight]="'24px'"
                        [spinnerSize]="20"
                        [imageSrc]="answer.imageUrl"
                        class="cell-image">
                      </image-with-loader>
                    </div>

                    <ng-template #emptySpace>
                      <div class="empty-space"></div>
                    </ng-template>
                  </ng-container>

                  <div class="single-answer-text">
                    {{ answer.text }}
                  </div>

                  <div
                    class="regular-item-controls-icon" *ngIf="isShowControlIcon((localizationService.vendorLocales$ | async).length, answer); else externalLabel">
                    <mat-icon
                      matRipple
                      [matRippleRadius]="10"
                      [matRippleUnbounded]="true"
                      [matRippleCentered]="true"
                      matSuffix
                      class="material-icons material-icons-outlined"
                      (click)="toEdit(answer.id, $event)">
                      edit
                    </mat-icon>
                    <mat-icon
                      matRipple
                      *ngIf="(!answer.isExternal && !answer.isExternalApi); else externalLabel"
                      [matRippleRadius]="10"
                      [matRippleUnbounded]="true"
                      [matRippleCentered]="true"
                      matSuffix
                      class="material-icons material-icons-outlined delete"
                      (click)="deleteAnswer(answer, $event)">
                      delete_outline
                    </mat-icon>
                  </div>
                  <ng-template #externalLabel>
                    <status-chip [customColor]="'#D5EE92'">
                      {{appData.EXTERNAL}}
                    </status-chip>
                  </ng-template>
                </div>
              </ng-template>

            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="img-flex-item" *ngIf="!hideImage">
        <div class="image-wrapper">
          <image-with-loader
            *ngIf="(answerImageUrl$ | async) && !(isEditedText$ | async); else emptySpace"
            [overlayHeight]="'24px'"
            [spinnerSize]="20"
            [imageSrc]="(answerImageUrl$ | async)"
            class="cell-image">
          </image-with-loader>
          <small-file-loader
            *ngIf="(isEditedText$ | async); else emptySpace"
            [image]="(answerImage$ | async)"
            (fileUpload)="addImageToMain($event)"
            (imageErrorMassage)="mainImage.error = $event">
          </small-file-loader>
          <ng-template #emptySpace>
            <div class="empty-space"></div>
          </ng-template>
        </div>
      </div>
    </div>
    <mat-error *ngFor="let error of errors$  | async">
      <i class="fas fa-exclamation-circle"></i>
      <span>{{error}}</span>
    </mat-error>
    <div  class="form-field-with-label">
      <mat-error *ngIf="(answerText$ | async)?.length > maxAnswerTextLength">
        <i class="fas fa-exclamation-circle"></i>
        <span>{{appData.MAX_LENGHT_128}}</span>
      </mat-error>
  	  <mat-error *ngIf="mainImage.error">
      	<i class="fas fa-exclamation-circle"></i>
        <span>{{appData.BIG_IMAGE}}</span>
      </mat-error>
    </div>
  </div>
