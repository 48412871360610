
<div
  class="form-field-with-label"
  *ngIf="(errors$  | async)  as errors"
  [ngClass]="{valid: (control?.touched && control?.valid || control?.disabled && control.value)}">
  <div #ref>
    <mat-label>
      <ng-content></ng-content>
      <i
        *ngIf="tooltipsText"
        class="fa-solid fa-circle-info"
        [matTooltipPosition]="tooltipPosition"
        #tooltip="matTooltip"
        [matTooltip]="tooltipsText"
        [matTooltipClass]="tooltipPosition"></i>
    </mat-label>
  </div>
  <span *ngIf=" ! ref.children.length"></span>
    <mat-form-field
      appearance="outline"
      [ngClass]="{'not-valid': errors.length > 0, 'small-form': isSmall}">
      <mat-select
        *ngIf="(multiple$ | async) && !multipleDisableEnabled"
        (focusout)="markAsTouched()"
        (selectionChange)="valueChanged($event)"
        [placeholder]="placeholder"
        [disabled]="disabled$ | async"
        [multiple]="(multiple$ | async)"
        [value]="value$ | async">
        <mat-option
          *ngFor="let option of selectOptions"
          [disabled]="disabled$ | async"
          [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>

      <mat-select
        *ngIf="(multiple$ | async) && multipleDisableEnabled"
        (focus)="markAsTouched()"
        (selectionChange)="valueChanged($event)"
        [placeholder]="placeholder"
        [disabled]="disabled$ | async"
        [multiple]="(multiple$ | async)"
        [value]="value$ | async">
        <mat-option
          *ngFor="let option of selectOptions"
          [disabled]="option.disabled"
          [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>

      <mat-select
        *ngIf="!(multiple$ | async)"
        (focus)="markAsTouched()"
        (selectionChange)="valueChanged($event)"
        [placeholder]="placeholder"
        [disabled]="disabled$ | async"
        [value]="value$ | async">
        <mat-option
          *ngFor="let option of selectOptions"
          [disabled]="option.disabled"
          [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngFor="let error of errors">
      <i class="fas fa-exclamation-circle"></i>
      <span>{{error}}</span>
    </mat-error>
  </div>
