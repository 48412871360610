import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabsControllService } from "../../../core/services/tabs-controll.service";

@Component({
  selector: 'main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainModalComponent {

  constructor(
    private tabsControllService: TabsControllService,
  ) {}
  
  onBodyScroll(event) {
    this.tabsControllService.bodyScroll(event);
  }
}
