import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { TabsEnum } from "../../common/Enums/TabsEnum";
import { cloneDeep } from 'lodash';

export interface TabRound {
  name: TabsEnum,
  isActive: boolean,
  id: string,
  order: number
}

@Injectable({
  providedIn: 'root'
})
export class TabsControllService {

  isTabNavigation: boolean;

  isScrollNavigation: boolean;

  tabsNameList$ = new BehaviorSubject<TabRound[]>([]);

  scrollToElement(tabId: string) {
    const element: HTMLElement = document.querySelector(`#${tabId}`);
    const parent = element.parentElement;
    if (element) {
      parent.scrollTo({top: element.offsetTop - 475, behavior: 'smooth'});
    }
  }

  init(tabList: TabRound[]) {
    const tabs = cloneDeep(tabList);
    tabs[0].isActive = true;
    this.tabsNameList$.next(tabs);
  }

  addNewTab(tab: TabRound) {
    tab.isActive = false;
    const tabList = this.tabsNameList$.value;
    tabList.push(tab);
    this.tabsNameList$.next(tabList.sort((a, b) => {
      if ( a.order < b.order ){
        return -1;
      }
      if ( a.order > b.order ){
        return 1;
      }
      return 0;
    }));
  }

  removeTab(tab: TabRound) {
    const tabList = this.tabsNameList$.value;
    this.tabsNameList$.next(tabList.filter(existTab => existTab.id !== tab.id));
  }

  isTabExist(tab: TabRound) {
    return this.tabsNameList$.value.some(existTab => existTab.id === tab.id);
  }

  async bodyScroll(event) {
    if (this.isTabNavigation) return;
    this.isScrollNavigation = true;
    this.tabsNameList$.next(
      await firstValueFrom(
        this.tabsNameList$
          .pipe(
            map(tabsList => {
              if (!this.isTabNavigation) {
                const foundedTab = this.getTabByScrollPosition(tabsList, event);
                if (foundedTab && !foundedTab.isActive) {
                  tabsList.forEach(tab => tab.isActive = false)
                  foundedTab.isActive = true;
                }
              }
              return tabsList;
            })
          )
      )
    );
  }

  getTabByScrollPosition(tabsList, event) {
    const scrolledElement: HTMLElement  = event.target;
    return tabsList.find((tab) => {
      const sectionElement: HTMLElement = scrolledElement.querySelector(`#${tab.id}`);
      if (sectionElement) {
        const sectionBounding = (sectionElement.getBoundingClientRect());
        const modalBounding = (scrolledElement.getBoundingClientRect());
        return (
          sectionBounding.top < modalBounding.top + 475 &&
          modalBounding.top + 475 < sectionBounding.bottom
        ) || sectionBounding.top + 475 == modalBounding.top;
      }
      return false;
    });
  }
}
