export enum TabsEnum {
  DETAILS = 'Details',
  SCORE = 'Score',
  PRIZE = 'Prize',
  QUESTIONS = 'Questions'
}

export const TabIds = {
  [TabsEnum.DETAILS]: 'round-wizzard-details-section',
  [TabsEnum.SCORE]: 'round-wizzard-score-section',
  [TabsEnum.PRIZE]: 'round-wizzard-prize-section',
  [TabsEnum.QUESTIONS]: 'round-wizzard-questions-section',
}

export const TabList = {
  [TabsEnum.DETAILS] : {
    id: TabIds[TabsEnum.DETAILS],
    isActive: false,
    name: TabsEnum.DETAILS,
    order: 0
  },
  [TabsEnum.PRIZE]: {
    id: TabIds[TabsEnum.PRIZE],
    isActive: false,
    name: TabsEnum.PRIZE,
    order: 1
  },
  [TabsEnum.SCORE]: {
    id: TabIds[TabsEnum.SCORE],
    isActive: false,
    name: TabsEnum.SCORE,
    order: 2
  },
  [TabsEnum.QUESTIONS]: {
    id: TabIds[TabsEnum.QUESTIONS],
    isActive: false,
    name: TabsEnum.QUESTIONS,
    order: 3
  },
}
