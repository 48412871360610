import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface EditBonusModalData {
  bonus: string,
}

@Component({
  selector: 'edit-bonus-modal',
  templateUrl: './edit-bonus-modal.component.html',
  styleUrls: ['./edit-bonus-modal.component.scss']
})
export class EditBonusModalComponent {

  public groupForm = new FormGroup({
    bonus: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<EditBonusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditBonusModalData
  ) {
    this.groupForm.get('bonus').patchValue(data.bonus);
  }

  save() {
    this.dialogRef.close(this.groupForm.get('bonus').value);
  }

}
