import { Directive, ElementRef, HostListener } from '@angular/core';
import { ScrollControlService} from "../../../../core/services/scroll-control.service";

@Directive({
  selector: '[scrollControl]'
})
export class ScrollControlDirective{
  constructor(private elementRef: ElementRef, private scrollControlService: ScrollControlService) {}


  @HostListener('opened')
  onOpened() {
    const element = this.elementRef.nativeElement;
    this.scrollControlService.openElement(element);
  }

  @HostListener('closed')
  onClosed() {
    const element = this.elementRef.nativeElement;
    this.scrollControlService.closeElement(element);
  }
}
