import { NgModule } from '@angular/core';
import {DatesFormatPipePipe} from "./dates.pipe";
import { FilterPipe } from './filter.pipe';
import { TransformTwoFaErrorPipe } from "./transform-two-fa-error.pipe";

@NgModule({
  declarations: [
    DatesFormatPipePipe,
    FilterPipe,
    TransformTwoFaErrorPipe
  ],
    exports: [
        DatesFormatPipePipe,
        FilterPipe,
        TransformTwoFaErrorPipe
    ],
  imports: [
  ]
})
export class PipesModule { }
