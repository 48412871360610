import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FilesService } from "../../../../core/services/files.service";
import { BehaviorSubject, filter, Subject, takeUntil } from "rxjs";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { TooltipPositionEnum } from '../../../Enums/TooltipPositionEnum';
import { APP_DATA } from "../../../../general.app.config";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'drag-and-drop-file-form',
  templateUrl: './drag-and-drop-file-form.component.html',
  styleUrls: ['./drag-and-drop-file-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DragAndDropFileFormComponent
    }
  ]
})
export class DragAndDropFileFormComponent implements ControlValueAccessor,  OnDestroy {

  constructor(
    private filesService: FilesService,
    private snackBarService: SnackBarService
  ) {
  }

  onChangeOutput: (id:string) => void;

  onTouched: () => void;

  disabled$ = new BehaviorSubject(false);

  url$ = new BehaviorSubject('');


  writeValue(): void {
  }

  registerOnChange(fn: any): void {
    this.onChangeOutput = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  isMandatoryError$ = new BehaviorSubject(false);

  @Input() tooltipText: string;

  /**
   * If current component opening with existing image assing this image to show it
   */
  @Input() set url(url: string) {
    if (url) {
      this.url$.next(url);
      this.getImgContentType(url);
    }
  }

  @Output() imageUrlChange = new EventEmitter<string>();


  TooltipPositionEnum = TooltipPositionEnum;

  /**
   * Indicator to show loader
   */
  isLoading$ = new BehaviorSubject(false);

  /**
   * Store size of image
   */
  imageSize$ = new BehaviorSubject(undefined);

  /**
   * Image size unit
   */
  imageSizeSymbol$ = new BehaviorSubject(undefined);

  appData = APP_DATA;

  /**
   * Flag to show to big size (4mb) error
   */
  isImageTooBig$ = new BehaviorSubject(false);

  private unsubscribe$: Subject<void> = new Subject();

  /**
   * If drag event canceled
   */
  onDragOver(event) {
    event.preventDefault();
  }

  /**
   * If drag event finished
   */
  onDropSuccess(event) {
    event.preventDefault();
    if (this.disabled$.value) return;

    this.onFileChange(event);
  }

  /**
   * If input event field vas changed in any way(drag and drop, click)
   */
  onChange(event) {
    this.uploadImage(event);
  }

  private onFileChange(files) {
    this.uploadImage(files)
  }


  uploadImage(event) {
    this.isLoading$.next(true);
    this.isMandatoryError$.next(false);
    const files = event.dataTransfer || event.target;
    this.uploadFile(files.files[0]);
  }

  /**
   * Create body for saving image, get image size
   */
  uploadFile(file) {
    this.isImageTooBig$.next(file.size > ((1024*1024) * 4));
    if (this.isImageTooBig$.value) {
      this.isLoading$.next(false);
      return;
    }
    const formdata = new FormData();
    formdata.append("file", file, file.name);
    this.filesService.uploadImage(formdata)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(uploadedFile => {
          this.isLoading$.next(false);
          this.url$.next(uploadedFile.url);
          this.imageUrlChange.next(uploadedFile.url);
          this.getImgContentType(uploadedFile.url)
          this.onChangeOutput(uploadedFile.url);
        },
        err => {
          this.isLoading$.next(false);
          this.snackBarService.showSnackBar(err.error.message, true);
        });
  }

  /**
   * Delete uploaded file
   */
  deleteImage() {
    this.url$.next(null);
    this.onChangeOutput(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Get image size
   */
  getImgContentType(img) {
    const xml = new XMLHttpRequest();

    xml.open('GET', img, true);
    xml.send();
    const imageSize$ = new BehaviorSubject<string>(null);

    xml.onreadystatechange = function() {
      if (this.readyState === this.HEADERS_RECEIVED) {
        imageSize$.next(xml.getResponseHeader('Content-Length'));
      }
    };
    imageSize$
      .pipe(
        filter(value => !!value),
        takeUntil(this.unsubscribe$)
        )
      .subscribe(val => {
        this.processImageSize(val)
    })
  }

  /**
   * Convert image size to unit
   */
  processImageSize(size) {
    if (size < 1024) {
      this.imageSize$.next(size);
      this.imageSizeSymbol$.next('b');
      return
    }
    if (size > (1024*1024)) {
      this.imageSize$.next(Math.round((size / (1024*1024)) * 10) / 10);
      this.imageSizeSymbol$.next('Mb');
      return;
    }
    this.imageSize$.next(Math.floor(size / 1024));
    this.imageSizeSymbol$.next('Kb');
  }
}
