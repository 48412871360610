import {FormGroup, ValidatorFn} from "@angular/forms";
import moment from 'moment';
import {Injectable} from "@angular/core";


@Injectable({providedIn: 'root'})

export class IsCloseDateAfterOpen {
  /**
   * Check that close date is after open date
   */
  public validateDates(): ValidatorFn {
    return (formGroup: FormGroup) => {

      const openDate = formGroup.get('openDate').value;
      const closeDate = formGroup.get('closeDate').value;

      if (!openDate || !closeDate) {
        return null;
      }
      if (moment(closeDate).isBefore(openDate)) {
        return { datesOrderWrong: true };
      }
      return null;
    };
  }

  roundDateErrorChecker(form: FormGroup) {
    if (form.errors?.datesOrderWrong) {
      form.controls['openDate'].setErrors({'incorrect': true});
      form.controls['closeDate'].setErrors({'incorrect': true});
      form.controls['openDate'].markAsTouched();
      form.controls['closeDate'].markAsTouched();
    } else {
      const openDateError = form.controls['openDate'].errors;
      if (openDateError) {
        form.controls['openDate'].setErrors(null);
        delete openDateError['incorrect'];
        form.controls['openDate'].setErrors(openDateError);
      }
      const closeDateError = form.controls['closeDate'].errors;
      if (closeDateError) {
        form.controls['closeDate'].setErrors(null);
        delete closeDateError['incorrect'];
        form.controls['closeDate'].setErrors(closeDateError);
      }
    }

    if (form.controls['openDate'].value
      && form.controls['closeDate'].value
      && !form.errors?.datesOrderWrong
      && !form.controls['openDate'].getError('invalidOpenDate')
      && !form.controls['closeDate'].getError('invalidCloseDate')
    ) {
      form.controls['openDate'].setErrors(null);
      form.controls['closeDate'].setErrors(null);
    }

    return form;
  }

}
