import { Component, Input, OnInit } from '@angular/core';
import { QuestionDetailsModel, VoidType } from "../../../../models/QuestionDetailsModel";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
  EventDetailsFormInterface,
  SingleAnswersDetailsFormInterface
} from "../../../../FormsModels/correct-answers-details-form-interface";
import { TextService } from "../../../../../core/services/text.service";
import { StreakQuestionListItemModel } from "../../../../models/StreakQuestionListItemModel";
import { CorrectAnswerHelperService } from "../../../../../core/services/streak/correct-answer-helper.service";
import { StreakQuestionDifficultyEnum } from "../../../../Enums/StreakQuestionDifficultyEnum";
import { LabelChipTypeEnum } from "../../../../Enums/LabelChipTypeEnum";

@Component({
  selector: 'right-list-answer',
  templateUrl: './right-list-answer.component.html',
  styleUrls: ['./right-list-answer.component.scss']
})
export class RightListAnswerComponent implements OnInit {

  @Input() voidOptions:  { value: VoidType, label: string }[];

  singleQuestionForm: FormGroup<SingleAnswersDetailsFormInterface | EventDetailsFormInterface>;

  question: QuestionDetailsModel | StreakQuestionListItemModel;

  difficultyLevel: StreakQuestionDifficultyEnum;

  isMatched: boolean;

  voidType: VoidType;

  @Input() set _question (question) {
    this.question = question;
    this.difficultyLevel = this.correctAnswerHelperService.getDifficultyLevel(question);
    this.isMatched = this.correctAnswerHelperService.getIsMatch(question);
    this.voidType = this.correctAnswerHelperService.getVoidType(question);
  }

  @Input() predictionNumber: number;

  @Input() isVoidTypeNeed: boolean;

  @Input() isWarningNeed: boolean;

	protected readonly LabelChipTypeEnum = LabelChipTypeEnum;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private correctAnswerHelperService: CorrectAnswerHelperService,
    public textService: TextService,
  ) {
  }
  ngOnInit(): void {
    this.singleQuestionForm = this.rootFormGroup.control;
  }

  getListOptions(options) {
    return options.map(option => {
      return {
        value: option.id,
        label: option.text
      }
    });
  }

  getProcessedResult(answerId) {
    if (answerId) {
      return this.question.answers.find(answer => answer.id === answerId)?.text;
    }
    return this.textService.createNameWithSpaceAndCapitalizeFirstLetter(this.voidType);
  }
}
