import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {Subject, takeUntil } from 'rxjs';
import {FilesService} from "../../../../core/services/files.service";
import {SnackBarService} from "../../../../core/services/snack-bar.service";

@Component({
  selector: 'small-file-loader',
  templateUrl: './small-file-loader.component.html',
  styleUrls: ['./small-file-loader.component.scss']
})
export class SmallFileLoaderComponent implements OnDestroy {

  constructor(
    private filesService: FilesService,
    private snackBarService: SnackBarService
  ) {
  }

  /**
   * If current component opening with existing image assing this image to show it
   */
  @Input() set image (image) {
    if (image) {
      this.uploadedFiles = image;
    }
  }

  /**
   * Send event with uploaded image data (url and id)
   */
  @Output() fileUpload = new EventEmitter<any>();

  @Output() imageErrorMassage = new EventEmitter<boolean>();

  /**
   * Use for show processing styles
   */
  processing: boolean;
  /**
   * Indicator to show loader
   */
  isLoading = false;

  /**
   * Assigned uploaded file to show image
   */
  uploadedFiles: any;

  private unsubscribe$: Subject<void> = new Subject();

  /**
   * If input event field vas changed in any way(drag and drop, click)
   */
  onChange(event) {
    this.uploadImage(event);
  }

  uploadImage(event) {
    this.isLoading = true;
    this.imageErrorMassage.emit(false);
    const files = event.dataTransfer || event.target;
    this.uploadFile(files.files[0]);
  }

  /**
   * Create body for saving image, get image size
   */
  uploadFile(file) {
    if (file.size > ((1024*1024) * 4)) {
      this.isLoading = false;
      this.imageErrorMassage.emit(true);
      return;
    }
    const formdata = new FormData();
    formdata.append("file", file, file.name);
    this.filesService.uploadImage(formdata)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(uploadedFile => {
          this.isLoading = false;
          this.uploadedFiles = uploadedFile;
          this.fileUpload.emit(uploadedFile);
        },
        err => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(err.error.message, true);
        });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showLoadIcon() {
    if (!this.uploadedFiles) return true
    if (this.uploadedFiles.imageUrl || this.uploadedFiles.url) return false;
    return true;
  }

  deleteImage() {
    this.uploadedFiles = null;
    this.fileUpload.emit(null);
  }
}
