export class TeamDetailsModel {
  id: number
  name: string
  imageUrl: string
  color: string
}

export class CreateTeamDetailsModel {
  name: string
  imageUrl?: string
  color?: string
}
