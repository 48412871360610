import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
  EventDetailsFormInterface
} from "../../../common/FormsModels/correct-answers-details-form-interface";
import { EventDetailsModel } from "../../../common/models/EventDetailsModel";

@Component({
  selector: 'event-score-section',
  templateUrl: './event-score-section.component.html',
  styleUrls: ['./event-score-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventScoreSectionComponent implements OnInit{

  eventForm: FormGroup<EventDetailsFormInterface>;

  @Input() roundEvent: EventDetailsModel;

  constructor(
    private rootFormGroup: FormGroupDirective,
  ) {
  }
  ngOnInit(): void {
    this.eventForm = this.rootFormGroup.control;
  }


}
