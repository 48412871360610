import {APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {AuthGuard} from "./core/guards/auth.guard";
import {authInterceptorProviders} from "./core/interseptors/auth.interceptor";
import {UriConfig} from "./app.config";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {CoreModule} from "./core/core.module";
import {CommonModule} from "@angular/common";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {MaterialModule} from "./material/material.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { NgxMatMomentModule,
} from "@angular-material-components/moment-adapter";
import {PipesModule} from "./common/modules/pipes/pipes.module";
import {MatIconModule} from "@angular/material/icon";
import {CommonComponentsModule} from "./common/components/common-components.module";
import {DirectivesModule} from "./common/modules/directives/directives.module";
import {MatTooltipModule} from '@angular/material/tooltip';
import { initializeAppFactory } from './initializeAppFactory';
import {SaasSettingsService} from "./core/services/saas-settings.service";
import { AuthService } from './core/services/auth.service';
import {LocalStorageService} from "./core/services/local-storage.service";
import { RoundWizzardModule } from "./new-round-wizzard/round-wizzard.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    NgxMatMomentModule,
    CommonComponentsModule,
    PipesModule,
    DirectivesModule,
    MatTooltipModule,
    RoundWizzardModule
  ],
  providers: [
    AuthGuard,
    authInterceptorProviders,
    UriConfig,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [SaasSettingsService, HttpClient, AuthService, LocalStorageService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule {
}
