<wizzard-section>
  <div header>SCORE</div>
  <div body>
    <ng-container [formGroup]="eventForm">
      <right-score-answer
        [event]="roundEvent">
      </right-score-answer>
    </ng-container>
  </div>
</wizzard-section>
