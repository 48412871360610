import { Injectable } from '@angular/core';
import { SettingsConfigItemModel } from "../../common/models/SettingsConfigItemModel";
import { LocalStorageEnum } from "../../common/Enums/LocalStorageEnum";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {


  setItem(prop: string, value: any): void {
    localStorage.setItem(prop, JSON.stringify(value));
  }

  getItem(prop: string): any {
    return JSON.parse(localStorage.getItem(prop));
  }

  removeItem(prop: string) {
    localStorage.removeItem(prop);
  }

  clear() {
    localStorage.clear();
  }

  getToken(): string {
    return this.getItem(LocalStorageEnum.TOKEN_KEY)
  }

  getRefreshToken(): string {
    return this.getItem(LocalStorageEnum.REFRESHTOKEN_KEY)
  }

  setToken(token: string) {
    this.setItem(LocalStorageEnum.TOKEN_KEY, token)
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(LocalStorageEnum.REFRESHTOKEN_KEY, refreshToken)
  }

  logOut() {
    this.removeItem(LocalStorageEnum.TOKEN_KEY)
    this.removeItem(LocalStorageEnum.REFRESHTOKEN_KEY)
    this.removeItem(LocalStorageEnum.ROLE)
    if (this.getItem(LocalStorageEnum.TWO_FA)) {
      this.removeItem(LocalStorageEnum.TWO_FA)
    }
  }

  getSettingsConfig() {
    return this.getItem(LocalStorageEnum.SETTINGS);
  }

  setSettingsConfig(config: SettingsConfigItemModel[]) {
    this.setItem(LocalStorageEnum.SETTINGS, config)
  }

  getHash() {
    return this.getItem(LocalStorageEnum.HASH);
  }

  setHash(hash: string) {
    this.setItem(LocalStorageEnum.HASH, hash)
  }

  getAccessTokenPreview() {
    return this.getItem(LocalStorageEnum.ACCESS_TOKEN_PREVIEW);
  }

  setAccessTokenPreview(token: string) {
    this.setItem(LocalStorageEnum.ACCESS_TOKEN_PREVIEW, token);
  }

  removeAccessTokenPreview() {
    this.removeItem(LocalStorageEnum.ACCESS_TOKEN_PREVIEW);
  }

  getRefreshTokenPreview() {
    return this.getItem(LocalStorageEnum.REFRESH_TOKEN_PREVIEW);
  }

  setRefreshTokenPreview(token: string) {
    this.setItem(LocalStorageEnum.REFRESH_TOKEN_PREVIEW, token);
  }

  removeRefreshTokenPreview() {
    this.removeItem(LocalStorageEnum.REFRESH_TOKEN_PREVIEW);
  }

  setIsStreakTenant(isStreak) {
    this.setItem(LocalStorageEnum.IS_STREAK, isStreak);
  }

  getIsStreakTenant() {
    return this.getItem(LocalStorageEnum.IS_STREAK);
  }

  removeIsStreakTenant() {
    this.removeItem(LocalStorageEnum.IS_STREAK);
  }
}
